import { AddEditItemForm } from './AddEditItemForm';
import { usePartEditor } from './usePartEditor';

export const AddEditItemDetails = ({ refresh, onClose }: { refresh?: () => void; onClose?: () => void }) => {
  const { form, partCategory, part, publishing, attributes, publishPart } = usePartEditor();

  const onSubmit: typeof publishPart = async (values) => {
    const newPart = await publishPart(values);
    refresh?.();
    return newPart;
  };

  return (
    <AddEditItemForm
      form={form}
      partCategory={partCategory}
      part={part}
      attributes={attributes}
      onClose={onClose}
      loading={publishing}
      onSubmit={onSubmit}
    />
  );
};
