import { useEffect, useMemo } from 'react';

import { isNotNil } from 'helpers/isNotNil';
import { useWrappedPaginatedGet } from 'hooks-api/useWrappedApiCall';

import { useDocumentsCache } from '../WorkRequestOrderDetail/Attachments/useDocumentsCache';
import type { Part, PartListingProps } from './types';

export const useCatalogCategoryParts = ({
  partCatalogId,
  partCategoryId,
  overridePartName,
  onlyAssemblies,
  onlyAssembliesWithBop,
  reset,
}: Pick<PartListingProps, 'partCatalogId' | 'partCategoryId' | 'overridePartName'> & {
  onlyAssemblies?: boolean;
  onlyAssembliesWithBop?: boolean;
  reset?: () => void;
}) => {
  const { requestDocumentDetails } = useDocumentsCache();
  const {
    data: parts,
    setDefaultOpts: setDefaultOptsParts,
    loading: loadingParts,
    fetchNextPage: fetchMoreParts,
    entireCount,
    reset: refresh,
  } = useWrappedPaginatedGet<Part>('moab/part', { lazy: true });
  useEffect(() => {
    requestDocumentDetails(parts.flatMap((p) => p.partImages?.map((i) => i.document.documentId)).filter(isNotNil));
  }, [parts, requestDocumentDetails]);

  useEffect(() => {
    if ((isNotNil(partCatalogId) && isNotNil(overridePartName)) || isNotNil(partCategoryId)) {
      reset?.();
      setDefaultOptsParts({
        lazy: false,
        defaultConfig: {
          params: {
            ...(onlyAssemblies || onlyAssembliesWithBop ? { isAssembly: true } : {}),
            ...(onlyAssembliesWithBop ? { assemblyHasBop: true } : {}),
            partCatalogId,
            ...(isNotNil(overridePartName)
              ? {
                  name: `eq:${overridePartName}`,
                }
              : {
                  partCategoryId,
                  orderBy: 'name:asc',
                }),
          },
        },
      });
    } else {
      setDefaultOptsParts({
        lazy: true,
      });
      refresh();
    }
  }, [
    reset,
    overridePartName,
    partCatalogId,
    partCategoryId,
    setDefaultOptsParts,
    onlyAssemblies,
    onlyAssembliesWithBop,
    refresh,
  ]);

  return useMemo(
    () => ({
      parts,
      loadingParts,
      fetchMoreParts,
      entireCount,
      refresh,
    }),
    [fetchMoreParts, loadingParts, parts, entireCount, refresh],
  );
};
