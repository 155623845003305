import { useCallback, useEffect, useRef, useState } from 'react';

import { ActionIcon, Divider, Flex, Loader, Text } from '@mantine/core';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { isNil, isNotNil } from 'helpers/isNotNil';
import { useWrappedGet, useWrappedPaginatedGet } from 'hooks-api/useWrappedApiCall';
import { TriggerOnVisible } from 'hooks/useOnScreen';
import type { CompanyId } from 'types/types-api';

import { CategoryMenu } from './CategoryMenu';
import type { PartCatalogId, PartCategory, PartCategoryId } from './types';

const oneSizeDown = {
  sm: 'xs',
  md: 'sm',
  lg: 'md',
} as const;

type Props = {
  autoSelectFirst?: boolean;
  disabled?: boolean;
  companyId: CompanyId;
  partCatalogId: PartCatalogId;
  selectedCategoryId: PartCategoryId | null;
  setSelectedCategoryId: (partCategoryId: PartCategoryId | null) => void;
  openToCategoryId?: PartCategoryId;
  allowRootSelection?: boolean;
  size?: 'sm' | 'md' | 'lg';
  withIcon?: boolean;
  allowAddingCategories?: boolean;
};

const Category = ({
  partCategory: initialPartCategory,
  hidden = false,
  depth = 0,
  size = 'sm',
  withIcon = true,
  allowAddingCategories = false,
  allowRootSelection,
  refreshParent,
  preOpenedCategoryIds,
  ...props
}: {
  refreshParent: () => void;
  partCategory: PartCategory;
  preOpenedCategoryIds: PartCategoryId[];
  hidden?: boolean;
  depth?: number;
} & Props) => {
  const [partCategory, setPartCategory] = useState(initialPartCategory);
  const zeroDepth = depth === 0 && !allowRootSelection;
  const { partCatalogId, companyId, selectedCategoryId, setSelectedCategoryId, disabled } = props;
  const [expanded, setExpanded] = useState(zeroDepth || allowRootSelection);
  const [hasEverBeenExpanded, setHasEverBeenExpanded] = useState(expanded);
  useEffect(() => {
    if (!hasEverBeenExpanded && expanded) setHasEverBeenExpanded(true);
  }, [expanded, hasEverBeenExpanded]);

  const {
    data: subCategories,
    fetchNextPage,
    loading,
    refetch: refreshChildren,
  } = useWrappedPaginatedGet<PartCategory>('moab/partCategory', {
    lazy: true,
    defaultConfig: {
      params: {
        parentPartCategoryId: partCategory.partCategoryId,
        partCatalogId,
        orderBy: 'name:asc',
        companyId,
      },
    },
  });

  useEffect(() => {
    if (subCategories.length > 0 && preOpenedCategoryIds.includes(partCategory.partCategoryId)) {
      setExpanded(true);
    }
  }, [partCategory.partCategoryId, preOpenedCategoryIds, subCategories.length]);

  const hasSubCategories = subCategories.length > 0 || partCategory.hasSubCategories;
  const selected = selectedCategoryId === partCategory.partCategoryId;
  // using depth === 0 instead of zeroDepth
  // since we should never be able to collapse the root category no matter what
  const expandDisabled = !hasSubCategories || depth === 0;

  return (
    <>
      <Flex
        className="hover-title"
        style={{
          display: hidden && !selected ? 'none' : 'flex',
          userSelect: 'none',
        }}
        justify="space-between"
        bg={selected ? `gray.${disabled ? '3' : '2'}` : undefined}
        align="center"
        py={3}
        onClick={!zeroDepth && !disabled ? () => setSelectedCategoryId(partCategory.partCategoryId) : undefined}
      >
        <Flex align="center" gap={oneSizeDown[size]}>
          <ActionIcon
            ml={4 + 8 * depth}
            size={size}
            disabled={expandDisabled}
            variant={expandDisabled ? 'transparent' : 'subtle'}
            onClick={(e) => {
              if (loading || disabled) return;
              setExpanded(!expanded);
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <EvolveIcon
              size="xs"
              color="inherit"
              icon={
                // eslint-disable-next-line no-nested-ternary
                !hasSubCategories ? null : expanded ? 'ArrowDown' : 'ArrowRight'
              }
            />
          </ActionIcon>
          <Text c={zeroDepth ? 'dimmed' : undefined} fw={selected ? 500 : undefined}>
            {partCategory.partCategoryName}
          </Text>
          {selected && withIcon && <EvolveIcon color="green.5" icon="CheckCircle" size={size} />}
        </Flex>
        {allowAddingCategories && (
          <CategoryMenu
            allowUpdateCategory={depth !== 0}
            partCatalogId={partCatalogId}
            partCategory={partCategory}
            onCategoryCreated={(c) => {
              refreshChildren();
              setExpanded(true);
              setSelectedCategoryId(c);
            }}
            onCategoryUpdated={setPartCategory}
            onCategoryDeleted={() => {
              if (partCategory.partCategoryId === selectedCategoryId) {
                setSelectedCategoryId(partCategory.parentPartCategory?.partCategoryId ?? null);
              }
              refreshParent();
            }}
          />
        )}
      </Flex>
      <Divider hidden={hidden && !selected} />
      {hasEverBeenExpanded && (
        <>
          {subCategories.map((c) => (
            <Category
              key={c.partCategoryId}
              hidden={!expanded || (hidden && !selected)}
              partCategory={c}
              depth={depth + 1}
              size={size}
              withIcon={withIcon}
              allowAddingCategories={allowAddingCategories}
              refreshParent={refreshChildren}
              preOpenedCategoryIds={preOpenedCategoryIds}
              {...props}
            />
          ))}
          {expanded && (
            <TriggerOnVisible
              onVisible={fetchNextPage}
              height={34}
              loading={loading}
              loaderProps={{ size, ml: 48 + 8 * depth }}
            />
          )}
        </>
      )}
    </>
  );
};

export const CatalogCategoryNav = (props: Props) => {
  const {
    autoSelectFirst,
    allowRootSelection,
    partCatalogId,
    companyId,
    size = 'sm',
    selectedCategoryId,
    setSelectedCategoryId,
    openToCategoryId,
  } = props;
  const { apiCall: getCategory } = useWrappedGet<PartCategory>('moab/partCategory/:partCategoryId', { lazy: true });
  const {
    data: categories,
    setDefaultOpts,
    loading,
    refetch,
  } = useWrappedPaginatedGet<PartCategory>('moab/partCategory', { lazy: true });

  const [preOpenedCategoryIds, setPreOpenedCategoryIds] = useState<PartCategoryId[]>([]);
  const preOpenedCatalogIdRef = useRef<PartCatalogId>(partCatalogId);

  useEffect(() => {
    if (preOpenedCatalogIdRef.current !== partCatalogId) {
      if (preOpenedCategoryIds.length > 0) {
        setPreOpenedCategoryIds([]);
      }
      return;
    }
    if (isNotNil(openToCategoryId)) {
      const categoryId = preOpenedCategoryIds[0] ?? openToCategoryId;
      getCategory({ url: `moab/partCategory/${categoryId}` }).then(({ parentPartCategory, ...category }) => {
        if (isNil(category.partCatalog)) return;
        if (category.partCatalog.partCatalogId !== preOpenedCatalogIdRef.current) {
          preOpenedCatalogIdRef.current = category.partCatalog?.partCatalogId;
          setPreOpenedCategoryIds([]);
          return;
        }
        if (isNotNil(parentPartCategory)) {
          setPreOpenedCategoryIds((cats) => [parentPartCategory.partCategoryId, ...cats]);
        } else {
          setSelectedCategoryId(openToCategoryId);
        }
      });
    }
  }, [getCategory, openToCategoryId, partCatalogId, preOpenedCategoryIds, setSelectedCategoryId]);

  useEffect(() => {
    if (!allowRootSelection && categories.some((c) => c.partCategoryId === selectedCategoryId)) {
      setSelectedCategoryId(null);
    }
  }, [allowRootSelection, categories, selectedCategoryId, setSelectedCategoryId]);

  useEffect(() => {
    if (autoSelectFirst && categories.length > 0 && isNil(selectedCategoryId)) {
      setSelectedCategoryId(categories[0].partCategoryId);
    }
  }, [autoSelectFirst, categories, selectedCategoryId, setSelectedCategoryId]);

  const refresh = useCallback(() => {
    refetch();
    setSelectedCategoryId(null);
  }, [refetch, setSelectedCategoryId]);

  useEffect(() => {
    setDefaultOpts({
      lazy: false,
      defaultConfig: {
        params: {
          parentPartCategoryId: '',
          partCatalogId,
          orderBy: 'name:asc',
          companyId,
        },
      },
    });
  }, [partCatalogId, companyId, setDefaultOpts]);

  return (
    <Flex direction="column" fz={size}>
      {loading ? (
        <Loader variant="dots" size={size} my={size} />
      ) : (
        categories.map((c) => (
          <Category
            key={c.partCategoryId}
            partCategory={c}
            size={size}
            refreshParent={refresh}
            preOpenedCategoryIds={preOpenedCategoryIds}
            {...props}
          />
        ))
      )}
    </Flex>
  );
};
