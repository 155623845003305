import { notifications } from '@mantine/notifications';

import { useWrappedPost } from 'hooks-api/useWrappedApiCall';
import type { WorkRequestItem } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/types';

import type { WorkOrderItem } from '../types';
import type { WorkRequestOrderProps } from '../WorkRequestOrderItemsContext';
import { AddCatalogItemToWorkOrderBody, AddCatalogItemToWorkRequestBody } from './AddItems/AddCatalogPartCard';
import { PartCategoryPartAdder, PartToAddWithDecisions } from './AddItems/PartCategoryPartAdder';
import type { PartListingProps } from './AddItems/types';

export const WorkRequestOrderPartAdder = ({ ...props }: PartListingProps & WorkRequestOrderProps) => {
  const { apiCall: addCatalogItemToWorkOrder } = useWrappedPost<WorkOrderItem, AddCatalogItemToWorkOrderBody>(
    'shop/workOrderItem/fromCatalog',
  );

  const { apiCall: addCatalogItemToWorkRequest } = useWrappedPost<WorkRequestItem, AddCatalogItemToWorkRequestBody>(
    'shop/workRequestItem/fromCatalog',
  );

  const onAddParts = async (items: PartToAddWithDecisions[]) => {
    await Promise.all(
      items.map(({ part: { partId }, ...item }) =>
        'workOrder' in props
          ? addCatalogItemToWorkOrder({
              ...item,
              partId,
              workOrderId: props.workOrder.workOrderId,
            })
          : addCatalogItemToWorkRequest({
              ...item,
              partId,
              workRequestId: props.workRequest.workRequestId,
            }),
      ),
    ).then((res) => {
      if (res.length === 1) {
        const item = res[0];
        notifications.show({
          title: 'Successfully added',
          message: `Added ${'workOrderItemName' in item ? item.workOrderItemName : item.workRequestItemName} to ${
            'workOrder' in props ? 'work order' : 'work request'
          }`,
          color: 'green',
        });
      } else {
        notifications.show({
          title: 'Successfully added',
          message: `Added ${items.length} item${items.length === 1 ? '' : 's'} to ${
            'workOrder' in props ? 'work order' : 'work request'
          }`,
          color: 'green',
        });
      }
    });
  };

  return (
    <>
      <PartCategoryPartAdder onAddParts={onAddParts} requireDecisions {...props} />
    </>
  );
};
