import { useState } from 'react';

import {
  Accordion,
  ActionIcon,
  Avatar,
  Button,
  FileButton,
  Flex,
  LoadingOverlay,
  SelectProps,
  Switch,
  Text,
  Textarea,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import type { UseFormReturnType } from '@mantine/form';
import { notifications } from '@mantine/notifications';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { AgGridStyleTooltip } from 'components/Mantine/AgGridStyleTooltip';
import { ModalButtons } from 'components/Mantine/ModalButtons';
import { useEvolveNavigate } from 'components/Mantine/Navigation/useEvolveNavigate';
import { WrappedMultiSelect, WrappedSelect } from 'components/Mantine/TypeSafeSelect';
import { isNil, isNotNil } from 'helpers/isNotNil';
import useSetupModule from 'hooks/useSetupModule';
import {
  useManufacturers,
  type ManufacturerId,
} from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/useManufacturers';
import {
  UnitOfMeasureId,
  useUnitOfMeasure,
} from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/useUnitOfMeasure';
import { NewUnitOfMeasureModal } from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/NewUnitOfMeasureModal';
import type {
  Part,
  PartCategory,
  PartCategoryId,
} from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/AddItems/types';
import {
  useDocumentImage,
  useSelectImage,
} from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/AddTasks/useDocumentImage';

import { NewManufacturerModal } from '../NewManufacturerModal';
import type {
  PartAttribute,
  PartAttributeId,
  PartAttributeSelectVariantId,
  PartAttributeVariantDataId,
} from '../types';

export type PartFormBody = PartCreateBody & {
  newImage: File | null;
};

export type PartCreateBody = Pick<Part, 'partName' | 'description'> & {
  partCategoryId: PartCategoryId;
  unitOfMeasureId: UnitOfMeasureId;
  manufacturerId: ManufacturerId;
  partAttributeVariantData: {
    partAttributeId: PartAttributeId;
    selectValueIds: PartAttributeSelectVariantId[];
  }[];
};

export type PartUpdateBody = Omit<PartCreateBody, 'partAttributeVariantData'> & {
  addPartAttributeVariantData?: {
    partAttributeId: PartAttributeId;
    selectValueIds: PartAttributeSelectVariantId[];
  }[];
  updatePartAttributeVariantData?: {
    partAttributeVariantDataId: PartAttributeVariantDataId;
    selectValueIds: PartAttributeSelectVariantId[];
  }[];
  deletePartAttributeVariantData?: PartAttributeVariantDataId[];
};

type Props = {
  form: UseFormReturnType<PartFormBody>;
  partCategory: PartCategory;
  attributes: PartAttribute[];
  onSubmit: (values: Props['form']['values']) => void;
  onClose?: () => void;
  part?: Part;
  disabled?: boolean;
  loading?: boolean;
};

export const AttributeSelector = ({
  attribute: {
    partAttributeName,
    partAttributeType: { partAttributeTypeName },
    selectVariants,
    allowWriteInVariant,
  },
  value,
  onChange,
  writeInAllowed,
  onWriteIn,
  disabled,
}: {
  attribute: Pick<PartAttribute, 'partAttributeName' | 'selectVariants' | 'allowWriteInVariant' | 'partAttributeType'>;
  value: PartAttributeSelectVariantId[] | string | null;
  onChange: (values: PartAttributeSelectVariantId[]) => void;
  disabled?: boolean;
} & (
  | {
      writeInAllowed: true;
      onWriteIn: (value: string) => void;
    }
  | {
      writeInAllowed?: false;
      onWriteIn?: (value: string) => void;
    }
)) => {
  const theme = useMantineTheme();
  const data = [
    ...selectVariants.map((v) => ({
      label: `${v.textValue}`,
      value: v.partAttributeSelectVariantId,
    })),
    ...(typeof value === 'string'
      ? [
          {
            label: value,
            value,
          },
        ]
      : []),
  ];
  const props: Partial<Omit<SelectProps, 'filter' | 'defaultValue' | 'size'>> = {
    label: (
      <Flex gap={4} align="center">
        {partAttributeName}
        {allowWriteInVariant && (
          <AgGridStyleTooltip label="Write-in values allowed" withArrow openDelay={200}>
            <EvolveIcon icon="WriteInItem" size="sm" color="inherit" />
          </AgGridStyleTooltip>
        )}
      </Flex>
    ),
    placeholder: partAttributeTypeName,
    searchable: true,
    disabled,
    clearable: true,
    creatable: writeInAllowed,
    onCreate: (v) => {
      onWriteIn?.(v);
      return null;
    },
    getCreateLabel: (query) => (
      <Flex gap={4} c={theme.primaryColor} align="center">
        <EvolveIcon icon="WriteInItem" color="inherit" size="sm" /> <b>{query}</b>
      </Flex>
    ),
  };
  if (partAttributeTypeName === 'Single Select') {
    return (
      <WrappedSelect<PartAttributeSelectVariantId | string>
        {...props}
        data={data}
        value={typeof value === 'string' ? value : value?.[0] ?? null}
        onChange={(v) => onChange(isNotNil(v) ? [v as PartAttributeSelectVariantId] : [])}
      />
    );
  }
  if (partAttributeTypeName === 'Multi Select') {
    return (
      <WrappedMultiSelect<PartAttributeSelectVariantId | string>
        {...props}
        data={data}
        value={typeof value === 'string' ? [value] : value ?? []}
        onChange={(v) => onChange(v as PartAttributeSelectVariantId[])}
        nowrap
      />
    );
  }
  // `partAttributeTypeName === 'Range'` not supported
  return null;
};

export type ConvertToAssemblyAdditionalDetails = {
  part: Part | undefined;
  formValues?: Props['form']['values'];
};

export const AddEditItemForm = ({
  form,
  attributes,
  part,
  onSubmit,
  disabled = false,
  onClose,
  loading,
  partCategory,
}: Props) => {
  const { currentModule } = useSetupModule();
  const navigate = useEvolveNavigate('Catalog Setup');
  const theme = useMantineTheme();
  const { onImageSelected, previewUrl } = useSelectImage();
  const { manufacturers } = useManufacturers();
  const [newUom, setNewUom] = useState<string>();
  const [newManufacturerName, setNewManufacturerName] = useState<string>();
  const existingImageUrl = useDocumentImage(part?.partImages?.[0]?.document.documentId);
  const [hideUnusedAttributes, setHideUnusedAttributes] = useState(isNotNil(part));

  const { uoms } = useUnitOfMeasure();

  return (
    <>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Flex direction="column" gap="sm">
          <Flex gap="md">
            <FileButton
              accept="image/png,image/jpg,image/jpeg"
              onChange={(i) => {
                if (!i) return;
                if (i.size > 2 * 1000 * 1024) {
                  notifications.show({
                    title: 'File too large',
                    message: 'Max file size is 2MB.',
                    color: 'red',
                  });
                  return;
                }
                onImageSelected(i);
                form.setFieldValue('newImage', i);
              }}
            >
              {(props) => (
                <ActionIcon disabled={disabled || loading} {...props} size={84} variant="subtle">
                  <Avatar
                    imageProps={{ style: { objectFit: 'contain' } }}
                    src={previewUrl ?? existingImageUrl}
                    size="xl"
                  >
                    <EvolveIcon icon="Upload" size="xl" />
                  </Avatar>
                  <LoadingOverlay visible={!!previewUrl && disabled} />
                </ActionIcon>
              )}
            </FileButton>
            <Text c="dimmed" fz="sm">
              Select the image you want to upload. Images should not be more than 2MB in size. Only GIF, PNG, or JPEG
              files are accepted.
            </Text>
          </Flex>

          <TextInput
            label="Part Name"
            maxLength={128}
            required
            autoFocus={isNil(part)}
            disabled={loading || disabled}
            {...form.getInputProps('partName')}
          />
          <Textarea
            label="Description"
            maxLength={255}
            disabled={loading || disabled}
            {...form.getInputProps('description')}
          />
          <WrappedSelect
            disabled
            label="Category"
            required
            value={partCategory.partCategoryId}
            onChange={() => {}}
            data={[
              {
                label: partCategory.partCategoryName,
                value: partCategory.partCategoryId,
              },
            ]}
            styles={{
              input: {
                color: 'black !important',
              },
            }}
          />
          <WrappedSelect
            label="Manufacturer"
            required
            disabled={loading || disabled}
            data={
              manufacturers?.map((m) => ({
                label: m.manufacturerName,
                value: m.manufacturerId,
              })) ?? []
            }
            maxLength={64}
            searchable
            creatable
            onCreate={(q) => {
              setNewManufacturerName(q);
              return null;
            }}
            getCreateLabel={(query) => <Text c={theme.primaryColor}>+ Create {query}</Text>}
            {...form.getInputProps('manufacturerId')}
          />
          <WrappedSelect
            label="Unit of Measure"
            required
            disabled={loading || disabled}
            searchable
            creatable
            onCreate={(q) => {
              setNewUom(q);
              return null;
            }}
            getCreateLabel={(query) => <Text c={theme.primaryColor}>+ Create {query}</Text>}
            data={
              uoms?.map((u) => ({
                label: `${u.unitOfMeasureName} (${u.unitOfMeasureCode})`,
                value: u.unitOfMeasureId,
              })) ?? []
            }
            {...form.getInputProps('unitOfMeasureId')}
          />

          <Accordion
            defaultValue="attributes"
            styles={{
              item: { border: 'none' },
              label: { padding: 3 },
              content: { padding: 3 },
            }}
          >
            <Accordion.Item value="attributes">
              <Accordion.Control px={3} my="xs">
                Attributes
              </Accordion.Control>
              <Accordion.Panel px={3}>
                <Flex direction="column" gap="xs">
                  <Switch
                    label="Hide unused attributes"
                    checked={hideUnusedAttributes}
                    onChange={() => setHideUnusedAttributes((u) => !u)}
                  />
                  {attributes.map((a) => {
                    const index = form.values.partAttributeVariantData.findIndex(
                      (v) => v.partAttributeId === a.partAttributeId,
                    );
                    if (
                      hideUnusedAttributes &&
                      form.values.partAttributeVariantData[index].selectValueIds.length === 0
                    ) {
                      return null;
                    }
                    return (
                      <AttributeSelector
                        key={a.partAttributeId}
                        attribute={a}
                        disabled={loading || disabled}
                        {...form.getInputProps(`partAttributeVariantData.${index}.selectValueIds`)}
                      />
                    );
                  })}
                </Flex>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
          {isNotNil(onClose) && (
            <ModalButtons
              type="submit"
              confirmationText="Publish"
              loading={loading}
              disabled={!form.isDirty() || !form.isValid() || disabled}
              leftComponent={
                part?.hasAssembly ? null : (
                  <Button
                    compact
                    variant="gradient"
                    leftIcon={<EvolveIcon icon="Assembly" size="sm" color="inherit" />}
                    disabled={loading || disabled}
                    onClick={() =>
                      navigate(
                        isNotNil(part)
                          ? `/${currentModule().toLocaleLowerCase()}/edit-catalog-item/${part.partId}`
                          : `/${currentModule().toLocaleLowerCase()}/add-catalog-item/${partCategory.partCategoryId}`,
                        {
                          additionalData: {
                            part,
                            formValues: form.values,
                          } as ConvertToAssemblyAdditionalDetails,
                        },
                      )
                    }
                  >
                    Assembly editor
                  </Button>
                )
              }
              onClose={onClose}
            />
          )}
        </Flex>
      </form>

      <NewManufacturerModal
        opened={!!newManufacturerName}
        manufacturerName={newManufacturerName ?? ''}
        onClose={() => setNewManufacturerName(undefined)}
        onCreate={({ manufacturerId }) => form.setFieldValue('manufacturerId', manufacturerId)}
      />
      <NewUnitOfMeasureModal
        opened={!!newUom}
        defaultValue={newUom}
        onClose={() => setNewUom(undefined)}
        onAdded={({ unitOfMeasureId }) => form.setFieldValue('unitOfMeasureId', unitOfMeasureId)}
      />
    </>
  );
};
