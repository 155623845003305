import type { ReactNode } from 'react';

import { Flex, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';

import { AlertingPopover } from 'components/Mantine/AlertingPopover';
import { isNil, isNotNil } from 'helpers/isNotNil';
import { useWrappedPost, useWrappedPut } from 'hooks-api/useWrappedApiCall';

import type { PartAttribute, PartAttributeId, SelectVariant } from './types';

type Props = {
  opened: boolean;
  onClose: () => void;
  refresh: () => void;
  partAttribute: PartAttribute | null;
  selectVariant?: SelectVariant;
  children?: ReactNode;
};

type AddVariantBody = {
  partAttributeId: PartAttributeId;
  textValue: string;
  codeValue?: string;
};

export const AddEditVariantPopover = ({ opened, refresh, onClose, selectVariant, partAttribute, children }: Props) => {
  const { apiCall: addVariant, loading: adding } = useWrappedPost<SelectVariant, AddVariantBody>(
    'moab/partAttributeSelectVariant',
  );
  const { apiCall: updateVariant, loading: updating } = useWrappedPut<SelectVariant, AddVariantBody>(
    `moab/partAttributeSelectVariant/${selectVariant?.partAttributeSelectVariantId}`,
  );
  const saving = adding || updating;

  const form = useForm<AddVariantBody>({
    validateInputOnBlur: true,
    validate: {
      textValue: (textValue) => {
        if (!textValue) return true;
        if (textValue === selectVariant?.textValue) return false;
        const existing = partAttribute?.selectVariants.find(
          (v) => v.textValue.toLocaleLowerCase() === textValue.toLocaleLowerCase(),
        );
        return existing ? 'Variant already exists' : false;
      },
      codeValue: (codeValue) => {
        if (!codeValue) return false;
        if (codeValue === selectVariant?.codeValue) return false;
        const existing = partAttribute?.selectVariants.find(
          (v) => v.codeValue?.toLocaleLowerCase() === codeValue.toLocaleLowerCase(),
        );
        return existing ? 'Code already exists' : false;
      },
    },
  });

  const onSubmit = (values: typeof form.values) => {
    (isNotNil(selectVariant) ? updateVariant(values, { method: 'PUT' }) : addVariant(values))
      .then((v) => {
        if (isNotNil(selectVariant)) {
          notifications.show({
            title: 'Successfully updated',
            message: `Variant ${v.textValue} updated`,
            color: 'green',
          });
        } else {
          notifications.show({
            title: 'Successfully created',
            message: `Variant ${v.textValue} created`,
            color: 'green',
          });
        }
      })
      .finally(() => {
        onClose();
        refresh();
      });
  };

  if (isNil(partAttribute)) return children;

  return (
    <>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <AlertingPopover
          onOpen={() => {
            const values = {
              partAttributeId: partAttribute.partAttributeId,
              textValue: selectVariant?.textValue ?? '',
              codeValue: selectVariant?.codeValue ?? '',
            };
            form.setValues(values);
            form.resetDirty(values);
          }}
          target={children}
          onClose={onClose}
          onSubmit={form.onSubmit(onSubmit)}
          opened={opened}
          isDirty={form.isDirty()}
          confirmButtonText="Save"
          loading={saving}
        >
          <Flex gap="xs">
            <TextInput
              size="xs"
              label="Variant"
              required
              autoFocus
              maxLength={64}
              style={{ width: '100%' }}
              disabled={saving}
              {...form.getInputProps('textValue')}
            />
            <TextInput
              size="xs"
              label="Code"
              style={{ width: 150 }}
              maxLength={6}
              disabled={saving}
              {...form.getInputProps('codeValue')}
            />
          </Flex>
        </AlertingPopover>
      </form>
    </>
  );
};
