import type { ReactNode } from 'react';

import { Checkbox, Flex } from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';

import { AlertingPopover } from 'components/Mantine/AlertingPopover';
import { WrappedSelect } from 'components/Mantine/TypeSafeSelect';
import { useWrappedPatch } from 'hooks-api/useWrappedApiCall';
import type { PartCatalogId } from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/AddItems/types';

import { AttributeNameField } from './AddAttribute/AttributeNameField';
import type { PartAttribute, PartAttributeTypeId } from './types';
import { usePartAttributes } from './usePartAttributes';

type Props = {
  opened: boolean;
  onClose: () => void;
  partCatalogId: PartCatalogId;
  attribute: PartAttribute;
  onAttributeUpdated: (updatedAttribute: PartAttribute) => void;
  children?: ReactNode;
};

type UpdateAttributeBody = Pick<PartAttribute, 'partAttributeName' | 'allowWriteInVariant'> & {
  partAttributeTypeId: PartAttributeTypeId;
};

export const EditAttributePopover = ({
  opened,
  onClose,
  partCatalogId,
  attribute: {
    partAttributeId,
    partAttributeName,
    partAttributeType: { partAttributeTypeId },
    allowWriteInVariant,
  },
  onAttributeUpdated,
  children,
}: Props) => {
  const { apiCall, loading } = useWrappedPatch<PartAttribute, UpdateAttributeBody>(
    `moab/partAttribute/${partAttributeId}`,
  );
  const form = useForm<UpdateAttributeBody>({
    validate: {
      partAttributeName: (v) => !v,
    },
  });

  const { partAttributeTypes } = usePartAttributes();

  const onSubmit = (values: typeof form.values) => {
    apiCall(values)
      .then((a) => {
        onAttributeUpdated(a);
        notifications.show({
          title: 'Successfully updated',
          message: `Attribute ${a.partAttributeName} updated`,
          color: 'green',
        });
      })
      .then(onClose);
  };

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <AlertingPopover
        loading={loading}
        disabled={!form.isValid()}
        confirmButtonText="Save"
        onSubmit={form.onSubmit(onSubmit)}
        opened={opened}
        onClose={onClose}
        isDirty={form.isDirty()}
        target={children}
        onOpen={() => {
          const defaultValues = {
            partAttributeName,
            partAttributeTypeId,
            allowWriteInVariant,
          };
          form.setValues(defaultValues);
          form.resetDirty(defaultValues);
        }}
      >
        <Flex direction="column" gap="xs">
          <AttributeNameField
            defaultValue={partAttributeName}
            partCatalogId={partCatalogId}
            onChange={form.getInputProps('partAttributeName').onChange}
            existingPartAttributeId={partAttributeId}
          />
          <WrappedSelect
            label="Attribute Type"
            withinPortal
            data={
              partAttributeTypes
                // Range not yet supported
                ?.filter((t) => t.partAttributeTypeName !== 'Range')
                .map((t) => ({
                  label: t.partAttributeTypeName,
                  value: t.partAttributeTypeId,
                })) ?? []
            }
            {...form.getInputProps('partAttributeTypeId')}
          />
          <Checkbox
            label="Allow write-in variants"
            defaultChecked={form.values.allowWriteInVariant}
            {...form.getInputProps('allowWriteInVariant')}
          />
        </Flex>
      </AlertingPopover>
    </form>
  );
};
