import { useState } from 'react';

import { ActionIcon, Card, Divider, Flex, Loader, Popover, Text } from '@mantine/core';

import { useModule } from 'app/Modules/ModuleContext';
import type { NavigationModule } from 'app/Modules/types';
import { EvolveIcon } from 'assets/icons/EvolveIcon';
import useSetupModule from 'hooks/useSetupModule';
import { themePrimaryColorMap } from 'styles/theme/mantineTheme';
import type { EModuleType } from 'types/module';

const moduleShortName: Partial<Record<EModuleType, string>> = {
  ADMIN: 'Ad',
  SHOP: 'Sh',
  FIELD: 'Fi',
  DESIGN: 'De',
  SYNC: '360',
  // TODO: These are wrong
  MANAGEMENT: 'Mg',
  MATERIALS: 'Ma',
  DATA_ANALYTICS: 'Da',
};

export const ModuleIcon = ({ moduleType, size = 56 }: { moduleType: EModuleType; size?: 24 | 56 }) => (
  <Card
    radius={size === 56 ? 'sm' : 'xs'}
    style={{
      width: size,
      height: size,
      userSelect: 'none',
    }}
    bg={themePrimaryColorMap[moduleType]}
    p={0}
  >
    <Flex justify="center" align="center" style={{ height: '100%' }}>
      <Text c="white" fs="italic" fw={600} fz={size * 0.4} mr={size / 16} opacity={0.8}>
        {moduleShortName[moduleType] ?? moduleType}
      </Text>
    </Flex>
  </Card>
);

const ModuleSelector = ({ module, onClose }: { module: NavigationModule; onClose: () => void }) => {
  const { currentModule, setSelectedModule } = useSetupModule();

  return (
    <Card
      onClick={() => {
        setSelectedModule(module.navigationModuleCode);
        onClose();
      }}
      bg={currentModule() === module.navigationModuleCode ? 'gray.8' : undefined}
      pt={6}
      pb={2}
      px={10}
      style={{ cursor: 'pointer', width: 76, maxWidth: 76 }}
    >
      <Flex direction="column" gap={4} align="center">
        <ModuleIcon moduleType={module.navigationModuleCode} />
        <Text c="white" fz="xs">
          {module.navigationModuleName}
        </Text>
      </Flex>
    </Card>
  );
};
export const ModuleSelectPopover = () => {
  const [opened, setOpened] = useState(false);
  const { data: modules, loading } = useModule();

  return (
    <Popover
      opened={opened}
      onChange={setOpened}
      keepMounted={false}
      position="bottom-end"
      portalProps={{
        style: {
          paddingRight: 20,
        },
      }}
    >
      <Popover.Target>
        <ActionIcon size="lg" onClick={() => setOpened((o) => !o)}>
          <EvolveIcon icon="Apps" />
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown mt="sm">
        <Flex direction="column" align="center">
          <Text>Select Module</Text>
          <Divider my="xs" style={{ width: '100%' }} />
          {loading && <Loader />}
          <Flex gap="xs" wrap="wrap" style={{ maxWidth: 248, userSelect: 'none' }}>
            {modules.map((module) => (
              <ModuleSelector key={module.navigationModuleCode} module={module} onClose={() => setOpened(false)} />
            ))}
          </Flex>
        </Flex>
      </Popover.Dropdown>
    </Popover>
  );
};
