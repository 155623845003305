import { useCallback, useEffect, useState } from 'react';

import { Flex, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';

import { ConfirmationModal } from 'components/Mantine/ConfirmationModal';
import { SearchableCheckList } from 'components/Mantine/SearchableCheckList';
import { isNil, isNotNil } from 'helpers/isNotNil';
import { useWrappedDelete, useWrappedPaginatedGet } from 'hooks-api/useWrappedApiCall';
import type { TaskType } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/types';
import { useDocumentsCache } from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/WorkRequestOrderDetail/Attachments/useDocumentsCache';

import { useWorkCellSetup } from '../WorkCellSetupContext';
import { CreateEditTaskTypeModal } from './CreateEditTaskTypeModal';
import { MapTaskToWorkCellPopover } from './MapTaskToWorkCellPopover';
import { TaskWithMenu } from './TaskWithMenu';

export const WorkCellTaskAdder = () => {
  const {
    selectedFacility,
    mutationRunning,
    activeShopConfiguration,
    draftShopConfiguration,
    loadingDraftShopConfiguration,
    tasksNeedRefreshing,
    setTasksNeedRefreshing,
  } = useWorkCellSetup();
  const { requestDocumentDetails } = useDocumentsCache();
  const [selectedTasks, setSelectedTasks] = useState<TaskType[]>([]);
  const [editingTaskType, setEditingTaskType] = useState<TaskType>();
  const [deletingTaskType, setDeletingTaskType] = useState<TaskType>();

  const { apiCall: deleteTaskApiCall, loading: deleting } = useWrappedDelete<unknown>('shop/taskType/TODO');
  const { data, setDefaultOpts, refetch, ...paginatedGet } = useWrappedPaginatedGet<TaskType>('shop/taskType', {
    lazy: true,
  });
  useEffect(() => {
    setDefaultOpts({
      lazy: true,
      defaultConfig: {
        params: {
          companyId: selectedFacility.companyId,
          shopConfigurationId:
            draftShopConfiguration?.shopConfigurationId ?? activeShopConfiguration.shopConfigurationId,
          orderBy: 'taskTypeName:asc',
          showAllTaskTypes: true,
        },
      },
    });
  }, [
    activeShopConfiguration.shopConfigurationId,
    // the object, not just the ID, such that we can easily trigger a re-fetch
    // of the task types when the Work Cell Setup is updated
    draftShopConfiguration,
    selectedFacility.companyId,
    setDefaultOpts,
  ]);
  useEffect(() => {
    if (tasksNeedRefreshing) {
      refetch().finally(() => setTasksNeedRefreshing(false));
    }
  }, [refetch, setTasksNeedRefreshing, tasksNeedRefreshing]);

  useEffect(() => {
    requestDocumentDetails(data.map((d) => d.taskTypeImageId).filter(isNotNil));
  }, [data, requestDocumentDetails]);

  const deleteTaskType = useCallback(() => {
    if (isNil(deletingTaskType)) return;
    deleteTaskApiCall({ url: `shop/taskType/${deletingTaskType.taskTypeId}` }).then(() => {
      notifications.show({
        title: 'Successfully deleted',
        message: `Deleted ${deletingTaskType.taskTypeName}`,
        color: 'green',
      });
      setDeletingTaskType(undefined);
      setSelectedTasks([]);
      setTasksNeedRefreshing(true);
    });
  }, [deleteTaskApiCall, deletingTaskType, setTasksNeedRefreshing]);

  return (
    <>
      <Flex mb="md" align="center" gap="md">
        <Text fz="lg" fw={600}>
          Task Types
        </Text>
        <CreateEditTaskTypeModal
          taskType={editingTaskType}
          companyId={selectedFacility.companyId}
          refresh={() => setTasksNeedRefreshing(true)}
          onClose={() => setEditingTaskType(undefined)}
        />
      </Flex>
      {!loadingDraftShopConfiguration && (
        <SearchableCheckList
          paginatedGet={{ data, refetch, ...paginatedGet }}
          idKey="taskTypeId"
          onChange={setSelectedTasks}
          value={selectedTasks}
          rightSideComponent={
            isNotNil(draftShopConfiguration) ? (
              <MapTaskToWorkCellPopover onComplete={() => setSelectedTasks([])} taskTypes={selectedTasks} />
            ) : null
          }
          textInputProps={{ placeholder: 'Search tasks...', style: { width: '90%', maxWidth: 400 } }}
          flexListProps={{ pb: 'sm', style: { flex: '1 0 0' } }}
          getItemLabel={(task) => (
            <TaskWithMenu
              task={task}
              onClickEdit={() => setEditingTaskType(task)}
              onClickDelete={() => setDeletingTaskType(task)}
            />
          )}
          disabled={mutationRunning || isNil(draftShopConfiguration)}
          // Currently, a TaskType can only be mapped to a single work cell
          // So if it's already mapped, prevent the user from selecting it
          disableOption={(t) => isNotNil(t.workCellTaskTypes) && t.workCellTaskTypes.length > 0}
          hideCheckboxes={isNil(draftShopConfiguration)}
          searchDisabled={mutationRunning}
        />
      )}
      <ConfirmationModal
        title="Confirm delete"
        opened={isNotNil(deletingTaskType)}
        buttonColor="red"
        loading={deleting}
        onClose={() => setDeletingTaskType(undefined)}
        onConfirm={deleteTaskType}
      >
        Are you sure you want to delete the shop task type <b>{deletingTaskType?.taskTypeName}</b>?
      </ConfirmationModal>
    </>
  );
};
