import { useCallback, useMemo } from 'react';

import { Auth } from 'aws-amplify';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import rateLimit from 'axios-rate-limit';

import getEnvVars from 'envVars';

const axiosClient = rateLimit(axios.create(), { maxRPS: 9 });

export type EvolveURL =
  | `admin/${string}`
  | `design/${string}`
  | `shop/${string}`
  | `moab/${string}`
  | `docmgt/${string}`
  | `field/${string}`
  | `message/${string}`;

export type AdditionalRequestConfig<D = any> = Omit<AxiosRequestConfig<D>, 'url'> & {
  url?: EvolveURL;
};

type CallType = 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE';
type NoBodyCalls = 'GET' | 'DELETE';

const methodMap = {
  GET: axiosClient.get,
  POST: axiosClient.post,
  PATCH: axiosClient.patch,
  PUT: axiosClient.put,
  DELETE: axiosClient.delete,
} as const;

export const getJwtToken = async () => Auth.currentSession().then((s) => s.getIdToken().getJwtToken());
/**
 * Global function to get the JWT token for the logged in user
 * This is intended to be used by 360 Sync, which does not have import access to our code,
 * but whose code runs inside of ours all the same.
 */
// @ts-ignore
window.getJwtToken = getJwtToken;

type ApiVersion = 'v1';
export const getBaseApiUrl = async (version: ApiVersion = 'v1') =>
  getEnvVars().then((vars) => `${vars.REACT_APP_API_BASE_URL}/${version}`);

const responseMapper = <ReturnType>(res: AxiosResponse<ReturnType>): ReturnType =>
  res.status === 204 ? (undefined as ReturnType) : res.data ?? (res as ReturnType);

/**
 * Count of endpoint to total number of calls
 * For debugging purposes only, should probably be deleted before going to prod
 * */
const callCount: Record<string, number> = {};
export const getCallCount = () => callCount;

const callBuilder = async <ReturnType, DataType = any>({
  path,
  config: customConfig,
  method,
  body,
}: {
  path: EvolveURL;
  config?: AdditionalRequestConfig<DataType>;
} & (
  | {
      method: Extract<CallType, NoBodyCalls>;
      body?: never;
    }
  | {
      method: Exclude<CallType, NoBodyCalls>;
      body: DataType;
    }
)): Promise<ReturnType> => {
  const endpoint = await getBaseApiUrl();
  // Not all endpoints require auth. If we don't have a JWT, perform the call anyway.
  // eslint-disable-next-line no-console
  const token = await getJwtToken().catch((e) => console.debug('Could not get JWT', e));
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: '*/*',
  };
  const config = {
    ...customConfig,
    headers,
  };
  const url = `${endpoint}/${path}`;
  if (url in callCount) {
    callCount[url] += 1;
  } else {
    callCount[url] = 1;
  }
  if (method === 'GET' || method === 'DELETE') {
    return methodMap[method](url, config).then(responseMapper);
  }
  return methodMap[method](url, body, config).then(responseMapper);
};

const useEvolveApi = () => {
  const get = useCallback(
    <T, D = any>(path: EvolveURL, config?: AdditionalRequestConfig<D>) =>
      callBuilder<T, D>({ path, config, method: 'GET' }),
    [],
  );

  const post = useCallback(
    <T, D = any>(path: EvolveURL, body: D, config?: AdditionalRequestConfig<D>) =>
      callBuilder<T, D>({ path, body, config, method: 'POST' }),
    [],
  );

  const patch = useCallback(
    <T, D = any>(path: EvolveURL, body: any, config?: AdditionalRequestConfig<D>) =>
      callBuilder<T, D>({ path, body, config, method: 'PATCH' }),
    [],
  );

  const put = useCallback(
    <T, D = any>(path: EvolveURL, body: any, config?: AdditionalRequestConfig<D>) =>
      callBuilder<T, D>({ path, body, config, method: 'PUT' }),
    [],
  );

  const apiDelete = useCallback(
    <T, D = any>(path: EvolveURL, body: any, config?: AdditionalRequestConfig<D>) =>
      callBuilder<T, D>({ path, config, method: 'DELETE' }),
    [],
  );

  return useMemo(() => ({ get, post, patch, put, apiDelete }), [apiDelete, get, patch, post, put]);
};

export default useEvolveApi;
