import { ReactNode, createContext, useCallback, useEffect, useMemo } from 'react';

import { useUser } from 'app/UserContext';
import { isNotNil } from 'helpers/isNotNil';
import useGeneralContext from 'helpers/useGeneralContext';
import { useWrappedGet, useWrappedPost } from 'hooks-api/useWrappedApiCall';
import type { CompanyId } from 'types/types-api';

declare const manufacturerIdSymbol: unique symbol;
export type ManufacturerId = string & { [manufacturerIdSymbol]: never };

export type Manufacturer = {
  companyId: CompanyId | null;
  homePageUrl: string | null;
  manufacturerId: ManufacturerId;
  manufacturerName: string;
};

type ManufacturersContextType = {
  manufacturers: Manufacturer[] | undefined;
  addManufacturer: (manufacturerName: string) => Promise<Manufacturer | undefined>;
  loading: boolean;
  mutationRunning: boolean;
};

const ManufacturersContext = createContext<ManufacturersContextType | undefined>(undefined);

export const ManufacturersProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useUser();
  const {
    data: manufacturers,
    setDefaultOpts,
    loading,
    apiCall: refresh,
  } = useWrappedGet<Manufacturer[]>('moab/mfg', {
    lazy: true,
  });
  const { apiCall: addManufacturerApiCall, loading: mutationRunning } = useWrappedPost<
    Manufacturer,
    {
      manufacturerName: string;
      companyId: CompanyId;
    }
  >('moab/mfg');
  const companyId = user?.companyId;
  useEffect(() => {
    if (isNotNil(companyId)) {
      setDefaultOpts({ defaultConfig: { params: { companyId } } });
    }
  }, [setDefaultOpts, companyId]);

  const addManufacturer = useCallback<ManufacturersContextType['addManufacturer']>(
    async (manufacturerName) => {
      if (isNotNil(companyId)) {
        return addManufacturerApiCall({
          manufacturerName,
          companyId,
        }).then(async (m) => {
          await refresh();
          return m;
        });
      }
      return undefined;
    },
    [addManufacturerApiCall, companyId, refresh],
  );

  const value = useMemo<ManufacturersContextType>(
    () => ({
      manufacturers,
      addManufacturer,
      loading,
      mutationRunning,
    }),
    [addManufacturer, loading, manufacturers, mutationRunning],
  );
  return <ManufacturersContext.Provider value={value}>{children}</ManufacturersContext.Provider>;
};

export const useManufacturers = () => useGeneralContext(ManufacturersContext, 'Manufacturers');
