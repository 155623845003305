import { Tabs } from '@mantine/core';

import { EvolveIcon } from 'assets/icons/EvolveIcon';

import { AddEditItemDetails } from './AddEditItemDetails';
import { AddPartToAssembly } from './AddPartToAssembly';
import { AddTaskToAssembly } from './AddTaskToAssembly';
import { usePartEditor } from './usePartEditor';

export const AddEditItemPane = () => {
  const { selectedTab, setSelectedTab, publishing } = usePartEditor();

  return (
    <Tabs
      value={selectedTab}
      onTabChange={(t: typeof selectedTab) => setSelectedTab(t ?? selectedTab)}
      variant="outline"
      display="flex"
      style={{
        flex: selectedTab === 'details' ? '0 1 auto' : '1 1 auto',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <Tabs.List mb="md">
        <Tabs.Tab
          value="details"
          disabled={publishing}
          icon={<EvolveIcon icon="Assembly" dark={selectedTab === 'details'} />}
        >
          Properties
        </Tabs.Tab>
        <Tabs.Tab value="parts" disabled={publishing} icon={<EvolveIcon icon="Part" dark={selectedTab === 'parts'} />}>
          Add Parts
        </Tabs.Tab>
        <Tabs.Tab
          value="tasks"
          disabled={publishing}
          icon={<EvolveIcon icon="WorkCellTask" dark={selectedTab === 'tasks'} />}
        >
          Add Tasks
        </Tabs.Tab>
      </Tabs.List>
      <Tabs.Panel
        value="details"
        display="flex"
        px="xs"
        style={{ flexDirection: 'column', flex: '1 1 auto', overflow: 'auto' }}
      >
        <AddEditItemDetails />
      </Tabs.Panel>
      <Tabs.Panel value="parts">
        <AddPartToAssembly />
      </Tabs.Panel>
      <Tabs.Panel value="tasks" display="flex" style={{ flexDirection: 'column', flex: '1 1 auto' }}>
        <AddTaskToAssembly />
      </Tabs.Panel>
    </Tabs>
  );
};
