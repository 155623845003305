import { useCallback, useState, useEffect, useMemo } from 'react';

import { Checkbox, Flex, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { AgGridReact } from 'ag-grid-react';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import type { SelectedFacility } from 'components/FacilityAndProjectWrapper/FacilityOrProjectRequired';
import { ConfirmationModal } from 'components/Mantine/ConfirmationModal';
import { PageBreadcrumb } from 'components/Mantine/Navigation/PageBreadcrumb';
import { TextInputDebounced } from 'components/Mantine/TextInputDebounced';
import { WrappedSelect } from 'components/Mantine/TypeSafeSelect';
import { INCLUDE_DELETED_OPTIONS } from 'graphql/types';
import { useServerSideGrid } from 'helpers/ag-grid/useServerSideGrid';
import { useWrappedPaginatedGet, useWrappedPatch, useWrappedPost } from 'hooks-api/useWrappedApiCall';
import type { DepartmentId } from 'hooks/projectsAndFacilities/types';
import { DEPARTMENT_USER_INCLUDED_ADMIN_OPTIONS } from 'modules/Shop/ShopProperties/AdminTab/constants/constants';
import { UserId } from 'types/types-api';

import { DepartmentUser, DepartmentUserId, getMembersColDef } from './columnDefs';
import { InviteUsersModal } from './InviteUsersModal';

type Props = {
  shopDepartmentId: DepartmentId;
  selectedFacility: SelectedFacility;
};

type DepUserPostReq = {
  departmentId: DepartmentId;
  userId: UserId;
  userEmail: DepartmentUser['user']['userEmail'];
  isAdmin: boolean;
};

export const ShopMembersPage = ({ shopDepartmentId: departmentId, selectedFacility }: Props) => {
  const [showDeleted, setShowDeleted] = useState(false);
  const [selectedRows, setSelectedRows] = useState<DepartmentUser[]>([]);
  const [usersToDelete, setUsersToDelete] = useState<DepartmentUser[]>([]);
  const [adminsToAdd, setAdminsToAdd] = useState<DepartmentUser[]>([]);
  const [adminsToRemove, setAdminsToRemove] = useState<DepartmentUser[]>([]);

  const { fetchPage, setDefaultOpts, searchHandler } = useWrappedPaginatedGet<DepartmentUser>('admin/departmentUser', {
    lazy: true,
  });
  useEffect(() => {
    setDefaultOpts({
      lazy: true,
      defaultConfig: {
        params: {
          departmentId,
          includeDeleted: showDeleted ? INCLUDE_DELETED_OPTIONS.YES : undefined,
          includeAdmin: DEPARTMENT_USER_INCLUDED_ADMIN_OPTIONS.YES,
        },
      },
    });
  }, [setDefaultOpts, departmentId, showDeleted]);

  const { apiCall: deleteDepartmentUsers, loading: deleting } = useWrappedPatch<
    unknown,
    {
      departmentUserIds: DepartmentUserId[];
    }
  >('admin/departmentUser/multidelete');

  const { apiCall: updateDepUser, loading: updating } = useWrappedPost<unknown, DepUserPostReq>(
    'admin/departmentUser/',
  );

  const onDeleteSingleUser = useCallback((departmentUser: DepartmentUser) => {
    setUsersToDelete([departmentUser]);
  }, []);

  const onAddSingleAdmin = useCallback((departmentUser: DepartmentUser) => {
    setAdminsToAdd([departmentUser]);
  }, []);

  const onRemoveSingleAdmin = useCallback((departmentUser: DepartmentUser) => {
    setAdminsToRemove([departmentUser]);
  }, []);

  const colDef = useMemo(
    () =>
      getMembersColDef({
        showAdmins: true,
        deleteUser: onDeleteSingleUser,
        addAdmin: onAddSingleAdmin,
        removeAdmin: onRemoveSingleAdmin,
      }),
    [onAddSingleAdmin, onDeleteSingleUser, onRemoveSingleAdmin],
  );
  const { agGridProps, refreshGrid } = useServerSideGrid({
    tableName: 'shop-members',
    fetchPage,
    colDef,
    rowId: 'departmentUserId',
  });

  const onConfirmDeleteDepartmentUsers = () => {
    deleteDepartmentUsers({
      departmentUserIds: usersToDelete.map((u) => u.departmentUserId),
    }).then(() => {
      setUsersToDelete([]);
      setSelectedRows([]);
      agGridProps.ref.current?.api.deselectAll();
      refreshGrid();
    });
  };

  const onConfirmAddAdmins = async () => {
    await Promise.all(
      adminsToAdd.map((u) =>
        updateDepUser({ departmentId, userId: u.user.userId, userEmail: u.user.userEmail, isAdmin: true }),
      ),
    );
    notifications.show({
      color: 'green',
      message: `Successfully added ${adminsToAdd.length} admin${adminsToAdd.length > 1 ? 's' : ''}.`,
      title: 'Admin added',
    });
    setAdminsToAdd([]);
    setSelectedRows([]);
    agGridProps.ref.current?.api.deselectAll();
    refreshGrid();
  };

  const onConfirmRemoveAdmins = async () => {
    await Promise.all(
      adminsToRemove.map((u) =>
        updateDepUser({ departmentId, userId: u.user.userId, userEmail: u.user.userEmail, isAdmin: false }),
      ),
    );
    notifications.show({
      color: 'green',
      message: `Successfully removed ${adminsToRemove.length} admin${adminsToRemove.length > 1 ? 's' : ''}.`,
      title: 'Admin removed',
    });
    setAdminsToRemove([]);
    setSelectedRows([]);
    agGridProps.ref.current?.api.deselectAll();
    refreshGrid();
  };

  return (
    <>
      <PageBreadcrumb title="Shop Members" />
      <Flex style={{ width: '100%' }} mb="md" justify="space-between" align="center">
        <Flex align="center" gap="sm">
          <Text c="dimmed">{selectedRows.length} items selected</Text>
          <WrappedSelect
            disabled={selectedRows.length === 0}
            value={null}
            placeholder="Action"
            data={[
              { value: 'Add admin', disabled: !selectedRows.some((u) => !u.isAdmin) },
              { value: 'Remove admin', disabled: !selectedRows.some((u) => u.isAdmin) },
              { value: 'Delete' },
            ]}
            onChange={(selected) => {
              if (selected === 'Delete') {
                setUsersToDelete(selectedRows);
              } else if (selected === 'Add admin') {
                setAdminsToAdd(selectedRows.filter((u) => !u.isAdmin));
              } else if (selected === 'Remove admin') {
                setAdminsToRemove(selectedRows.filter((u) => u.isAdmin));
              }
            }}
          />
        </Flex>
        <Flex align="center" gap="sm">
          <Checkbox
            label="Show deleted users"
            checked={showDeleted}
            onChange={(e) => setShowDeleted(e.currentTarget.checked)}
          />
          <TextInputDebounced
            icon={<EvolveIcon icon="Search" color="inherit" />}
            onChange={searchHandler}
            placeholder="Search..."
          />
          <InviteUsersModal
            pageType="shop"
            refresh={refreshGrid}
            departmentId={departmentId}
            companyId={selectedFacility.companyId}
          />
        </Flex>
      </Flex>
      <div className="ag-theme-quartz" style={{ height: '100%' }}>
        <AgGridReact<DepartmentUser>
          {...agGridProps}
          rowSelection="multiple"
          rowMultiSelectWithClick
          onSelectionChanged={({ api }) => {
            setSelectedRows(api.getSelectedRows());
          }}
          noRowsOverlayComponent={() => 'No members found.'}
        />
      </div>
      <ConfirmationModal
        onConfirm={onConfirmDeleteDepartmentUsers}
        loading={deleting}
        onClose={() => {
          setUsersToDelete([]);
        }}
        opened={usersToDelete.length > 0}
        buttonColor="red"
        description={`Are you sure you want to delete ${usersToDelete.length === 1 ? 'this user' : 'these users'}?`}
      />
      <ConfirmationModal
        onConfirm={onConfirmAddAdmins}
        loading={updating}
        onClose={() => {
          setAdminsToAdd([]);
        }}
        opened={adminsToAdd.length > 0}
        description={`Are you sure you want to add ${
          adminsToAdd.length === 1 ? 'this user as an admin' : 'these users as admins'
        }?`}
      />
      <ConfirmationModal
        onConfirm={onConfirmRemoveAdmins}
        loading={updating}
        onClose={() => {
          setAdminsToRemove([]);
        }}
        opened={adminsToRemove.length > 0}
        buttonColor="red"
        description={`Are you sure you want to remove ${
          adminsToRemove.length === 1 ? 'this user as an admin' : 'these users as admins'
        }?`}
      />
    </>
  );
};
