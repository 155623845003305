import React, { ReactNode, useEffect, useMemo } from 'react';

import { useUser } from 'app/UserContext';
import { isNotNil } from 'helpers/isNotNil';
import useGeneralContext from 'helpers/useGeneralContext';
import { useWrappedGet } from 'hooks-api/useWrappedApiCall';

import { PartAttributeType } from './types';

type PartAttributesContextType = {
  partAttributeTypes: PartAttributeType[] | undefined;
  loading: boolean;
};

const PartAttributesContext = React.createContext<PartAttributesContextType | undefined>(undefined);

export const PartAttributesProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useUser();
  const {
    data: partAttributeTypes,
    loading,
    setDefaultOpts,
  } = useWrappedGet<PartAttributeType[]>('moab/partAttributeType', {
    lazy: true,
  });
  useEffect(() => {
    if (isNotNil(user)) {
      setDefaultOpts({
        lazy: false,
        defaultConfig: {
          params: {
            companyId: user.companyId,
          },
        },
      });
    }
  }, [setDefaultOpts, user]);
  return (
    <PartAttributesContext.Provider
      value={useMemo(
        () => ({
          partAttributeTypes,
          loading,
        }),
        [loading, partAttributeTypes],
      )}
    >
      {children}
    </PartAttributesContext.Provider>
  );
};

export const usePartAttributes = () => useGeneralContext(PartAttributesContext, 'PartAttributes');
