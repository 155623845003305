import type { ReactNode } from 'react';

import { Button, ButtonProps, DefaultMantineColor, Divider, Flex, Space, SpacingValue } from '@mantine/core';

export type ModalButtonProps = {
  cancellationText?: string;
  confirmationText?: string;
  onClose: () => void;
  buttonColor?: DefaultMantineColor;
  leftComponent?: ReactNode;
  loading?: boolean;
  disabled?: boolean;
  dividerMargin?: SpacingValue;
} & (
  | {
      type?: ButtonProps['type'];
      onConfirm: () => void;
    }
  | {
      type: 'submit';
      onConfirm?: never;
    }
);

export const ModalButtons = ({
  cancellationText = 'Cancel',
  confirmationText = 'Confirm',
  leftComponent = <Space />,
  buttonColor,
  loading,
  disabled,
  onClose,
  dividerMargin,
  onConfirm,
  type,
}: ModalButtonProps) => (
  <>
    <Divider my={dividerMargin} />
    <Flex justify="space-between" gap="xs" align="center">
      {leftComponent}
      <Flex gap="xs">
        <Button onClick={onClose} variant="subtle" color="gray" c="dark" disabled={loading}>
          {cancellationText}
        </Button>
        <Button color={buttonColor} loading={loading} disabled={disabled} onClick={onConfirm} type={type}>
          {confirmationText}
        </Button>
      </Flex>
    </Flex>
  </>
);
