import '@xyflow/react/dist/style.css';

import { useCallback, useEffect, useMemo } from 'react';

import { ActionIcon, Button, Card, Flex, LoadingOverlay, Text } from '@mantine/core';
import { MarkerType, ReactFlow, useReactFlow } from '@xyflow/react';
import { useNavigate } from 'react-router-dom';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { AgGridStyleTooltip } from 'components/Mantine/AgGridStyleTooltip';
import { BasePageHeader } from 'components/Mantine/BasePageHeader';
import { isNil } from 'helpers/isNotNil';
import useSetupModule from 'hooks/useSetupModule';
import { ConfirmNavigationModal } from 'modules/Field/WorkRequests/WorRequestsProperties/WorkRequestPropertiesAttachments/hooks/ConfirmNavigationModal';

import { NodeType, PartNode, TaskNode } from './nodes/AssemblyNode';
import { useAssemblyEditor } from './useAssemblyEditor';
import { usePartEditor } from './usePartEditor';
import { calculatePartNodeStartingPoint, calculateTaskNodeStartingPoint } from './utils';

const nodeTypes = {
  assembly: PartNode,
  part: PartNode,
  task: TaskNode,
} as const;

const PublishButton = ({ goBackToCatalog }: { goBackToCatalog: () => void }) => {
  const { publishing, form, publishPart, setSelectedTab } = usePartEditor();
  const { saveAssembly, saving, isDirty } = useAssemblyEditor();
  return (
    <Button
      onClick={() => {
        if (!form.isValid()) {
          setSelectedTab('details');
          form.validate();
          return;
        }
        publishPart(form.values).then(saveAssembly).then(goBackToCatalog);
      }}
      disabled={!form.isDirty() && !isDirty}
      loading={publishing || saving}
    >
      Publish assembly
    </Button>
  );
};

export const AssemblyEditorView = () => {
  const { currentModule } = useSetupModule();
  const navigate = useNavigate();
  const { fitView, zoomIn, zoomOut } = useReactFlow();
  const { part, publishing, form } = usePartEditor();
  const { isDirty, nodes, edges, onNodesChange, onEdgesChange, onConnect, addNode, loading, saving } =
    useAssemblyEditor();

  const goBackToCatalog = useCallback(() => {
    navigate(`/${currentModule().toLocaleLowerCase()}/catalog-setup?categoryId=${form.values.partCategoryId}`);
  }, [currentModule, form.values.partCategoryId, navigate]);

  useEffect(() => {
    if (nodes.length === 0 && isDirty) {
      const documentId = part?.partImages?.find((p) => p.isDefault)?.document.documentId;
      addNode({
        type: 'assembly',
        deletable: false,
        data: {
          part: {
            partId: part?.partId,
            partName: part?.partName ?? 'New Assembly',
            documentIds: documentId ? [documentId] : [],
            description: part?.description ?? null,
            hasAssembly: true,
            unitOfMeasureCode: part?.unitOfMeasure.unitOfMeasureCode ?? '?',
          },
        },
        position: {
          x: 550,
          y: 350,
        },
      });
    }
  }, [part, addNode, nodes.length, isDirty]);

  const selected = useMemo(() => nodes.find((n) => n.selected) ?? edges.find((e) => e.selected), [edges, nodes]);

  return (
    <>
      <BasePageHeader
        title={part?.partName ?? 'New Assembly'}
        parentPage={{
          title: 'Catalog Setup',
          url: '/catalog-setup',
        }}
        topRightComponent={
          <>
            <Button variant="subtle" disabled={publishing || saving} onClick={goBackToCatalog}>
              Cancel
            </Button>
            <PublishButton goBackToCatalog={goBackToCatalog} />
          </>
        }
      />

      <Card withBorder style={{ width: '100%', height: '100%' }}>
        <ReactFlow
          nodeTypes={nodeTypes}
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          proOptions={{ hideAttribution: true }}
          defaultEdgeOptions={{
            deletable: true,
            markerEnd: { type: MarkerType.ArrowClosed, color: '#666' },
            type: 'smoothstep',
            style: { strokeWidth: 2 },
          }}
          fitView
          minZoom={0.2}
          fitViewOptions={{ maxZoom: 1, padding: 0 }}
          snapGrid={[1, 1]}
          snapToGrid
        />

        <Card
          p={6}
          withBorder
          style={{
            position: 'absolute',
            right: 10,
            bottom: 10,
          }}
        >
          <Flex direction="column" gap="xs">
            <AgGridStyleTooltip label="Zoom in" withArrow openDelay={200} position="left">
              <ActionIcon size="lg" color="default" variant="light" onClick={() => zoomIn({ duration: 300 })}>
                <EvolveIcon icon="Add" color="inherit" size="sm" />
              </ActionIcon>
            </AgGridStyleTooltip>
            <AgGridStyleTooltip label="Zoom out" withArrow openDelay={200} position="left">
              <ActionIcon size="lg" color="default" variant="light" onClick={() => zoomOut({ duration: 300 })}>
                <Text fz="xl" fw={600}>
                  —
                </Text>
              </ActionIcon>
            </AgGridStyleTooltip>
            <AgGridStyleTooltip label="Zoom view to fit" withArrow openDelay={200} position="left">
              <ActionIcon
                size="lg"
                color="default"
                variant="light"
                onClick={() => fitView({ duration: 500, padding: 0 })}
              >
                <EvolveIcon icon="CollapsePane" color="inherit" />
              </ActionIcon>
            </AgGridStyleTooltip>
          </Flex>
        </Card>
        <Card
          p={6}
          withBorder
          style={{
            position: 'absolute',
            left: 10,
            bottom: 10,
          }}
        >
          <Flex direction="column" gap="xs">
            <AgGridStyleTooltip
              label={`Duplicate selected ${selected?.type}`}
              withArrow
              openDelay={200}
              position="right"
            >
              <ActionIcon
                size="lg"
                color="default"
                disabled={isNil(selected) || selected.type === 'assembly' || !('position' in selected)}
                variant="light"
                onClick={() => {
                  if (isNil(selected) || !('position' in selected)) return;
                  const position =
                    selected.type === 'part'
                      ? calculatePartNodeStartingPoint(nodes)
                      : calculateTaskNodeStartingPoint(nodes);
                  const newNode = addNode({
                    ...selected,
                    position,
                  } as NodeType);
                  onNodesChange([
                    {
                      id: selected.id,
                      type: 'select',
                      selected: false,
                    },
                    {
                      id: newNode.id,
                      type: 'select',
                      selected: true,
                    },
                  ]);
                }}
              >
                <EvolveIcon icon="Copy" color="inherit" />
              </ActionIcon>
            </AgGridStyleTooltip>
            <AgGridStyleTooltip
              label={`Delete selected ${selected?.type ?? 'edge'}`}
              withArrow
              openDelay={200}
              position="right"
            >
              <ActionIcon
                size="lg"
                color="red"
                disabled={isNil(selected) || selected.type === 'assembly'}
                variant="light"
                onClick={() => {
                  if (isNil(selected)) return;
                  onNodesChange([
                    {
                      id: selected.id,
                      type: 'remove',
                    },
                  ]);
                  onEdgesChange([
                    {
                      id: selected.id,
                      type: 'remove',
                    },
                  ]);
                }}
              >
                <EvolveIcon icon="Delete" color="inherit" />
              </ActionIcon>
            </AgGridStyleTooltip>
          </Flex>
        </Card>
        <LoadingOverlay visible={loading || publishing || saving} />
      </Card>

      <ConfirmNavigationModal isDirty={isDirty || form.isDirty()} />
    </>
  );
};
