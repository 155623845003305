import { useCallback, useEffect, useState } from 'react';

import { ActionIcon, Button, Card, Checkbox, Divider, Flex, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { AgGridStyleTooltip } from 'components/Mantine/AgGridStyleTooltip';
import { ConfirmationModal } from 'components/Mantine/ConfirmationModal';
import { isNotNil } from 'helpers/isNotNil';
import { useWrappedPatch } from 'hooks-api/useWrappedApiCall';
import { TriggerOnVisible } from 'hooks/useOnScreen';
import { CatalogPartCard } from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/AddItems/CatalogPartCard';
import type {
  PartCatalogId,
  PartCategoryId,
  PartId,
} from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/AddItems/types';
import { useCatalogCategoryParts } from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/AddItems/useCatalogCategoryParts';

import { AddEditItemModal } from './PartEditor/AddEditItemModal';

type Props = {
  partCatalogId: PartCatalogId;
  partCategoryId: PartCategoryId | null;
};

export const ManageItems = ({ partCatalogId, partCategoryId }: Props) => {
  const [editing, setEditing] = useState(false);
  const [confirmingDelete, setConfirmingDelete] = useState(false);
  const [selected, setSelected] = useState<PartId[]>([]);
  useEffect(() => {
    if (!editing) {
      setSelected([]);
      setConfirmingDelete(false);
    }
  }, [editing]);

  const resetEditing = useCallback(() => setEditing(false), []);

  const { parts, loadingParts, fetchMoreParts, refresh, entireCount } = useCatalogCategoryParts({
    partCatalogId,
    partCategoryId,
    reset: resetEditing,
  });
  const { apiCall: deleteParts, loading: deleting } = useWrappedPatch<unknown, { partIds: PartId[] }>(
    `moab/partCategory/${partCategoryId}/removeParts`,
  );

  const onDelete = () => {
    deleteParts({ partIds: selected }).then(() => {
      refresh();
      notifications.show({
        title: 'Successfully deleted',
        message: `${selected.length} part${selected.length === 1 ? 's' : ''} deleted.`,
        color: 'green',
      });
      setEditing(false);
    });
  };

  return (
    <>
      <Flex gap="lg" style={{ flex: '1 0 0', overflowX: 'auto' }}>
        <Card withBorder display="flex" style={{ flexDirection: 'column', flex: '3 0 0', minWidth: 400 }}>
          <Flex align="center" justify="space-between">
            <Flex align="center" gap="xl">
              <Text fw={600} fz="lg">
                Items in category
                <Text ml="xs" span c="dimmed" fw={400}>
                  {isNotNil(entireCount) && `${entireCount}`}
                </Text>
              </Text>
              {editing ? (
                <Flex gap="sm">
                  <Button variant="outline" c="dark" color="gray" onClick={() => setEditing(false)} disabled={deleting}>
                    Cancel
                  </Button>
                  <Button
                    loading={deleting}
                    disabled={selected.length === 0}
                    color="red"
                    onClick={() => setConfirmingDelete(true)}
                  >
                    Delete
                  </Button>
                </Flex>
              ) : (
                <>
                  {(entireCount ?? 0) > 0 && (
                    <AgGridStyleTooltip label="Edit multiple" withArrow>
                      <ActionIcon variant="outline" color="default" onClick={() => setEditing(true)}>
                        <EvolveIcon size="sm" icon="WriteInItem" color="inherit" />
                      </ActionIcon>
                    </AgGridStyleTooltip>
                  )}
                </>
              )}
            </Flex>
            <AddEditItemModal disabled={editing} refresh={refresh} partCategoryId={partCategoryId} />
          </Flex>
          <Divider mt="md" mb="lg" />
          <Flex direction="column" gap="xl" style={{ flex: '1 0 0', overflowY: 'auto' }}>
            {isNotNil(partCategoryId) ? (
              <>
                {entireCount === 0 && (
                  <Text c="dimmed" ml="md">
                    No items in category.
                  </Text>
                )}
                {parts.map((p) => (
                  <Flex key={p.partId} align="center" gap="md">
                    {editing && (
                      <Checkbox
                        checked={selected.includes(p.partId)}
                        disabled={deleting}
                        onChange={() => {
                          setSelected((s) => {
                            const newArr = [...s];
                            const i = s.indexOf(p.partId);
                            if (i < 0) return newArr.concat(p.partId);
                            newArr.splice(i, 1);
                            return newArr;
                          });
                        }}
                      />
                    )}
                    <CatalogPartCard
                      part={p}
                      rightComponent={
                        <AddEditItemModal
                          disabled={editing}
                          refresh={refresh}
                          part={p}
                          partCategoryId={partCategoryId}
                        />
                      }
                    />
                  </Flex>
                ))}
                <TriggerOnVisible onVisible={fetchMoreParts} loading={loadingParts} />
              </>
            ) : (
              <Text c="dimmed" ml="md">
                Select a category.
              </Text>
            )}
          </Flex>
        </Card>
      </Flex>
      <ConfirmationModal
        title="Confirm delete"
        onClose={() => setConfirmingDelete(false)}
        onConfirm={onDelete}
        opened={confirmingDelete}
        buttonColor="red"
        confirmationText="Delete"
        loading={deleting}
        requireUserEmail
      >
        Are you sure you want to delete{' '}
        <b>
          {selected.length} item{selected.length === 1 ? '' : 's'}?
        </b>
      </ConfirmationModal>
    </>
  );
};
