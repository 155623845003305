import { Flex, Tabs } from '@mantine/core';

import { NoRowsOverlay } from 'helpers/ag-grid/NoRowsOverlay';
import { isNotNil } from 'helpers/isNotNil';

import { CategorySetup } from './CategorySetup';
import { ManageItems } from './ManageItems';
import { useCatalogSetup } from './useCatalogSetup';

export const CatalogSetupDetailsPane = () => {
  const { selectedCatalogId, selectedCategoryId, selectedTab, setSelectedTab } = useCatalogSetup();
  return (
    <Tabs
      value={selectedTab}
      onTabChange={(t: typeof selectedTab) => setSelectedTab(t ?? selectedTab)}
      display="flex"
      style={{ height: '100%', flexDirection: 'column' }}
      variant="outline"
      orientation="horizontal"
      keepMounted={false}
    >
      <Tabs.List mb="xl">
        <Tabs.Tab value="manage-items">Manage Items</Tabs.Tab>
        <Tabs.Tab value="category-setup">Manage Attributes</Tabs.Tab>
      </Tabs.List>

      {isNotNil(selectedCatalogId) ? (
        <>
          <Tabs.Panel value="category-setup" display="flex" style={{ flexDirection: 'column', flex: '1 1 auto' }}>
            <CategorySetup partCatalogId={selectedCatalogId} partCategoryId={selectedCategoryId} />
          </Tabs.Panel>
          <Tabs.Panel value="manage-items" display="flex" style={{ flexDirection: 'column', flex: '1 1 auto' }}>
            <ManageItems partCatalogId={selectedCatalogId} partCategoryId={selectedCategoryId} />
          </Tabs.Panel>
        </>
      ) : (
        <Flex align="center" style={{ height: '100%' }}>
          <NoRowsOverlay label="Select a catalog to begin." icon="Assembly" />
        </Flex>
      )}
    </Tabs>
  );
};
