import { Modal, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';

import { ModalButtons } from 'components/Mantine/ModalButtons';
import { isNotNil } from 'helpers/isNotNil';
import {
  Manufacturer,
  useManufacturers,
} from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/useManufacturers';

type Props = {
  manufacturerName: string;
  opened: boolean;
  onClose: () => void;
  onCreate: (manufacturer: Manufacturer) => void;
};

const NewManufacturerForm = ({ onClose, onCreate, ...initialValues }: Omit<Props, 'opened'>) => {
  const { addManufacturer, mutationRunning, loading } = useManufacturers();
  const form = useForm<{
    manufacturerName: string;
  }>({ initialValues, validate: { manufacturerName: (n) => !n } });

  const onSubmit = ({ manufacturerName }: typeof form.values) => {
    addManufacturer(manufacturerName)
      .then((m) => isNotNil(m) && onCreate(m))
      .then(onClose);
  };

  return (
    <form
      onSubmit={(...e) => {
        form.onSubmit(onSubmit)(...e);
        e[0].stopPropagation();
      }}
    >
      <TextInput
        autoFocus
        label="Manufacturer Name"
        disabled={mutationRunning || loading}
        maxLength={64}
        {...form.getInputProps('manufacturerName')}
      />
      <ModalButtons
        type="submit"
        loading={loading || mutationRunning}
        disabled={!form.isValid()}
        onClose={onClose}
        confirmationText="Create"
        dividerMargin="md"
      />
    </form>
  );
};

export const NewManufacturerModal = ({ opened, ...props }: Props) => {
  const { onClose } = props;
  return (
    <Modal
      title="New Manufacturer"
      opened={opened}
      onClose={onClose}
      centered
      closeOnClickOutside={false}
      closeOnEscape={false}
    >
      <NewManufacturerForm {...props} />
    </Modal>
  );
};
