import { useEffect, useMemo, useState } from 'react';

import { Loader, Text, useMantineTheme } from '@mantine/core';

import { WrappedSelect } from 'components/Mantine/TypeSafeSelect';
import { isNotNil } from 'helpers/isNotNil';
import { useWrappedGet, useWrappedPaginatedGet } from 'hooks-api/useWrappedApiCall';
import type { PartCatalogId } from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/AddItems/types';

import type { PartAttribute, PartAttributeId } from '../types';

type Props = {
  partCatalogId: PartCatalogId;
  onChange: (value: string) => void;
  existingPartAttributeId?: PartAttributeId;
  value?: string;
  defaultValue?: string;
};

export const AttributeNameField = ({
  value: parentValue,
  onChange,
  partCatalogId,
  defaultValue,
  existingPartAttributeId,
}: Props) => {
  const theme = useMantineTheme();
  const [value, setValue] = useState(defaultValue);
  useEffect(() => {
    if (isNotNil(parentValue)) setValue(parentValue);
  }, [parentValue]);

  const [searchValue, setSearchValue] = useState('');
  const [error, setError] = useState('');

  const { data: nameObjs } = useWrappedGet<{ predefinedAttributeName: string }[]>('moab/partAttributePredefinedName');
  const predefinedAttributeNames = useMemo(() => nameObjs?.map((n) => n.predefinedAttributeName) ?? [], [nameObjs]);

  const { fetchPage: findAttribute, loading } = useWrappedPaginatedGet<PartAttribute>('moab/partAttribute', {
    lazy: true,
  });

  const setNewValue = (newValueUntrimmed: string) => {
    const newValue = newValueUntrimmed.trim();
    if (newValue === value) return;

    setValue(newValue);
    setSearchValue('');
    setError('');
    onChange('');
    if (!newValue) return;

    findAttribute(
      { skip: 0, take: 1 },
      {
        params: {
          name: `eq:${newValue}`,
          partCatalogId,
        },
      },
    ).then(({ data }) => {
      if (data.length === 0 || data[0].partAttributeId === existingPartAttributeId) {
        onChange(newValue);
      } else {
        setError(
          `This attribute already exists in this catalog under category ${data[0].partCategory.partCategoryName}.`,
        );
      }
    });
  };

  return (
    <WrappedSelect
      required
      label="Attribute Name"
      description="This attribute will also be available to all subcategories of this category"
      maxLength={32}
      value={value}
      data={
        !value || predefinedAttributeNames.includes(value)
          ? predefinedAttributeNames
          : [...predefinedAttributeNames, value]
      }
      clearable
      rightSection={loading ? <Loader size="sm" /> : null}
      searchable
      creatable
      withinPortal
      getCreateLabel={(query) => (
        <Text c={theme.primaryColor}>
          + <b>{query}</b>
        </Text>
      )}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          setNewValue(searchValue);
          e.currentTarget.blur();
        }
      }}
      onCreate={(query) => {
        setNewValue(query);
        return null;
      }}
      error={error}
      onBlur={() => setNewValue(searchValue)}
      onChange={(v) => setNewValue(v ?? '')}
      searchValue={searchValue}
      onSearchChange={(v) => {
        setSearchValue(v);
        if (v && v !== value) {
          setError('');
          onChange('');
        }
      }}
    />
  );
};
