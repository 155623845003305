import { useMemo, useState } from 'react';

import { ActionIcon, Loader, Menu } from '@mantine/core';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { isNotNil } from 'helpers/isNotNil';
import { useWrappedPatch } from 'hooks-api/useWrappedApiCall';
import { useSelectedProjectFacility } from 'hooks/projectsAndFacilities/useSelectedProjectFacility';
import { SPOOL_UOM, WRITE_IN_ASSEMBLY } from 'modules/Field/WorkRequests/WorkRequest/constants';
import type { WorkRequestItem } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/types';

import { AddToCatalogModal } from './AddToCatalogModal';
import { DeleteItemsModal } from './DeleteItemsModal';
import { EditItemPopover } from './EditItemPopover';
import { InheritFromBopModal } from './InheritFromBopModal';
import { RemakeDecisions } from './RemakeDecisions';
import type { CreateOrUpdateWorkOrderItemBody, CreateOrUpdateWorkRequestItemBody, WorkOrderItem } from './types';

type Props = {
  canEditItem: boolean;
  canEditTasks: boolean;
  parentIsCompleted: boolean;
  refresh: () => void;
} & (
  | {
      workOrderItem: WorkOrderItem;
      workOrderItemUpdated: (updatedWorkOrderItem: WorkOrderItem) => void;
    }
  | {
      workRequestItem: WorkRequestItem;
      workRequestItemUpdated: (updatedWorkRequestItem: WorkRequestItem) => void;
    }
);

export const ItemsMenu = ({ canEditItem, canEditTasks, parentIsCompleted, refresh, ...props }: Props) => {
  const { selectedItem } = useSelectedProjectFacility();
  const [opened, setOpened] = useState(false);
  const [menuItemSelected, setMenuItemSelected] = useState<
    'add-to-catalog' | 'inherit-bop' | 'edit' | 'remake-decisions' | 'delete' | undefined
  >();

  const { apiCall: updateWorkOrderItemApiCall, loading: saving1 } = useWrappedPatch<
    WorkOrderItem,
    CreateOrUpdateWorkOrderItemBody
  >(`shop/workOrderItem`);
  const { apiCall: updateWorkRequestItemApiCall, loading: saving2 } = useWrappedPatch<
    WorkRequestItem,
    CreateOrUpdateWorkRequestItemBody
  >(`shop/workOrderItem`);
  const saving = saving1 || saving2;

  const updateWorkRequestItem = async (values: CreateOrUpdateWorkRequestItemBody) => {
    if (!('workRequestItem' in props) || parentIsCompleted) return;
    const { workRequestItem, workRequestItemUpdated } = props;
    setMenuItemSelected(undefined);
    setOpened(false);
    await updateWorkRequestItemApiCall(values, {
      url: `shop/workRequestItem/${workRequestItem.workRequestItemId}`,
    }).then(workRequestItemUpdated);
  };

  const updateWorkOrderItem = async (values: CreateOrUpdateWorkOrderItemBody) => {
    if (!('workOrderItem' in props) || parentIsCompleted) return;
    const { workOrderItem, workOrderItemUpdated } = props;
    setMenuItemSelected(undefined);
    setOpened(false);
    await updateWorkOrderItemApiCall(values, {
      url: `shop/workOrderItem/${workOrderItem.workOrderItemId}`,
    }).then(workOrderItemUpdated);
  };

  const { itemName, itemTypeId, quantity, unitOfMeasureId, partId } =
    'workOrderItem' in props
      ? {
          itemName: props.workOrderItem.workOrderItemName,
          itemTypeId: props.workOrderItem.workOrderItemTypeId,
          ...props.workOrderItem,
        }
      : {
          itemName: props.workRequestItem.workRequestItemName,
          itemTypeId: props.workRequestItem.workRequestItemTypeId,
          ...props.workRequestItem,
        };

  const keepRendering = useMemo(
    () => opened || isNotNil(menuItemSelected) || saving,
    [menuItemSelected, opened, saving],
  );

  const canAddToCatalog = itemTypeId === WRITE_IN_ASSEMBLY && unitOfMeasureId !== SPOOL_UOM;

  if (!canEditItem && !canAddToCatalog) return null;

  return (
    <>
      <Menu position="bottom-end" withArrow withinPortal opened={opened} onChange={setOpened}>
        <Menu.Target>
          <ActionIcon disabled={saving} onClick={() => setOpened((o) => !o)} variant="transparent">
            {saving ? (
              <Loader size="sm" />
            ) : (
              <EvolveIcon className={keepRendering ? '' : 'show-on-row-hover'} icon="MoreOptsVert" color="inherit" />
            )}
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          {canAddToCatalog && (
            <Menu.Item onClick={() => setMenuItemSelected('add-to-catalog')}>Add item to catalog</Menu.Item>
          )}
          {itemTypeId === WRITE_IN_ASSEMBLY && canEditTasks && selectedItem?.type === 'FACILITY' && (
            <Menu.Item onClick={() => setMenuItemSelected('inherit-bop')}>Inherit BOP</Menu.Item>
          )}
          {canEditItem && (
            <>
              <Menu.Item onClick={() => setMenuItemSelected('edit')}>Edit item</Menu.Item>
              {isNotNil(partId) && (
                <Menu.Item onClick={() => setMenuItemSelected('remake-decisions')}>Edit decisions</Menu.Item>
              )}
              <Menu.Item color="red" onClick={() => setMenuItemSelected('delete')}>
                Delete
              </Menu.Item>
            </>
          )}
        </Menu.Dropdown>
      </Menu>

      <EditItemPopover
        onSubmit={async ({ itemName: newItemName, ...values }) => {
          if ('workOrderItem' in props) {
            await updateWorkOrderItem({
              workOrderItemName: newItemName,
              ...values,
            });
          } else if ('workRequestItem' in props) {
            await updateWorkRequestItem({
              workRequestItemName: newItemName,
              ...values,
            });
          }
        }}
        opened={menuItemSelected === 'edit'}
        onClose={() => setMenuItemSelected(undefined)}
        defaultValues={{
          itemName,
          quantity,
          unitOfMeasureId,
        }}
      />
      <AddToCatalogModal
        onClose={() => setMenuItemSelected(undefined)}
        opened={menuItemSelected === 'add-to-catalog'}
        {...('workRequestItem' in props
          ? {
              workRequestItem: props.workRequestItem,
              updateWorkRequestItem,
            }
          : {
              workOrderItem: props.workOrderItem,
              updateWorkOrderItem,
            })}
      />
      <InheritFromBopModal
        onClose={() => setMenuItemSelected(undefined)}
        opened={menuItemSelected === 'inherit-bop'}
        {...('workRequestItem' in props
          ? { workRequestItems: [props.workRequestItem] }
          : { workOrderItems: [props.workOrderItem] })}
      />
      <DeleteItemsModal
        opened={menuItemSelected === 'delete'}
        refresh={refresh}
        onClose={() => setMenuItemSelected(undefined)}
        {...('workRequestItem' in props
          ? { workRequestItems: [props.workRequestItem] }
          : { workOrderItems: [props.workOrderItem] })}
      />
      <RemakeDecisions
        opened={menuItemSelected === 'remake-decisions'}
        onClose={() => setMenuItemSelected(undefined)}
        {...props}
      />
    </>
  );
};
