import { useRef, useCallback, useEffect } from 'react';

import { CalendarProvider, layoutOptions } from '@evolvemep/foresite-components-ui-react';
import { DragDropContext } from 'react-beautiful-dnd';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import ComponentPaneLayout from 'app/Layout/ReactGridLayout/ComponentPaneLayout';
import { FacilityAndProjectWrapper } from 'components';
import { FacilityOrProjectRequired } from 'components/FacilityAndProjectWrapper/FacilityOrProjectRequired';
import { ItemsDataGridProProvider } from 'components/ItemsDatagridPro';
import { PopoverFilterProvider } from 'components/Popover/FilterPopover/context/PopoverFilterContext';
import { isNotNil } from 'helpers/isNotNil';
import { WorkOrderUpdateProvider } from 'hooks-api/useWorkOrderUpdate';
import { DrawingRegister, DrawingRegisterProvider } from 'modules/Design/DrawingRegister';
import { ProjectProvider } from 'modules/Field/LocationsAndWorkPhases/contexts/ProjectContext';
import { UpdateWorkRequestStatusProvider } from 'modules/Field/WorkRequests/WorkRequest/hooks/useUpdateWorkRequestStatus/useUpdateWorkRequestStatus';
import { WorkRequestItemsProvider } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestItemsContext';
import { WorkRequestsProvider as GeneralWorkRequestProvider } from 'modules/Field/WorkRequests/WorkRequestsContext';
import { WorkRequestsPage } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestsPage/WorkRequestsPage';
import BillOfMaterials from 'modules/Materials/BillOfMaterials/BillOfMaterials';
import BillOfMaterialsById from 'modules/Materials/BillOfMaterialsById/BillOfMaterialsById';
import BillOfMaterialsFlyoutMenu from 'modules/Materials/BillOfMaterialsById/BillOfMaterialyFlyoutMenu';
import { BOMbyIdProvider } from 'modules/Materials/BillOfMaterialsById/BOMbyIdContext';
import { CatalogSetupCategorySelector } from 'modules/Materials/CatalogSetup/CatalogSetupPage/CatalogSetupCategorySelector';
import { CatalogSetupDetailsPane } from 'modules/Materials/CatalogSetup/CatalogSetupPage/CatalogSetupDetailsPane';
import { AddEditItemPane } from 'modules/Materials/CatalogSetup/CatalogSetupPage/PartEditor/AddEditItemPane';
import { AssemblyEditorView } from 'modules/Materials/CatalogSetup/CatalogSetupPage/PartEditor/AssemblyEditorView';
import { AssemblyEditorProvider } from 'modules/Materials/CatalogSetup/CatalogSetupPage/PartEditor/useAssemblyEditor';
import { PartEditorProvider } from 'modules/Materials/CatalogSetup/CatalogSetupPage/PartEditor/usePartEditor';
import { CatalogSetupProvider } from 'modules/Materials/CatalogSetup/CatalogSetupPage/useCatalogSetup';
import { BOMItemProvider } from 'modules/Materials/components/BillOfMaterialsItemPopup/BOMItemContext';
import { AssignationModeProvider } from 'modules/Materials/features/assignation-mode';
import FiltersProviderBase from 'modules/Materials/FiltersProviderBase';

import { ShopDepartmentFallback } from './components/ShopDepartmentFallback';
import { ShopDepartmentRequired } from './components/ShopDepartmentFallback/ShopDepartmentRequired';
import { CustomAssembliesProvider } from './CustomAssemblies/CustomAssembliesContext';
import { CustomAssemblyTypesList } from './CustomAssemblies/CustomAssemblyTypes/CustomAssemblyTypesList';
import { CustomAssemblyTypesProvider } from './CustomAssemblies/CustomAssemblyTypesContext';
import { CustomAssembly } from './CustomAssembly/CustomAssembly';
import TaskViewer from './Fabrication/TaskViewer/TaskViewer';
import { WorkCellQueuePage } from './Fabrication/WorkCellQueue/WorkCellQueuePage/WorkCellQueuePage';
import { InsightsPage } from './Insights/InsightsPage';
import { InsightsProvider } from './Insights/useInsights';
import NoShopDptOverlay from './NoShopDptOverlay';
import { ShopMembersPage } from './ShopMembers/ShopMembersMantine/ShopMembersPage';
import { ShopSetupWrapper } from './ShopSetup/WorkCellSetup/ShopSetupPage/ShopSetupWrapper';
import { WorkCellTaskAdder } from './ShopSetup/WorkCellSetup/ShopSetupPage/TaskTypes/WorkCellTaskAdder';
import { WorkCellSetupPage } from './ShopSetup/WorkCellSetup/ShopSetupPage/WorkCellSetupPage';
import { WorkCellsPage } from './WorkCells/WorkCellsPage/WorkCellsPage';
import { WorkRequestOrderDetailsPane } from './WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/WorkRequestOrderDetailsPane';
import { WorkOrderItemsTable } from './WorkOrders/WorkOrder/WorkOrderItemsPage/WorkOrderItemsTable';
import { WorkOrderSelectedWrapper } from './WorkOrders/WorkOrder/WorkOrderItemsPage/WorkOrderSelectedWrapper';
import { WorkRequestItemsTable } from './WorkOrders/WorkOrder/WorkOrderItemsPage/WorkRequestItemsTable';
import { WorkRequestSelectedWrapper } from './WorkOrders/WorkOrder/WorkOrderItemsPage/WorkRequestSelectedWrapper';
import { WorkOrdersProvider } from './WorkOrders/WorkOrdersContext';
import WorkOrderWRProvider from './WorkOrders/WorkOrdersList/context/WorkOrderWRProvider';
import { WorkOrdersPage } from './WorkOrders/WorkOrdersPage/WorkOrdersPage';
import { WorkRequestsProvider } from './WorkRequests/WorkRequestsContext';

export const Dashboard = () => (
  <ComponentPaneLayout>
    <Navigate to="work-requests" />
  </ComponentPaneLayout>
);

export const InsightsNavContent = () => (
  <InsightsProvider>
    <ComponentPaneLayout>
      <InsightsPage />
    </ComponentPaneLayout>
  </InsightsProvider>
);

export const ShopMembersInterfaceNavContent = () => (
  <ShopDepartmentRequired>
    {(shopDepartmentId, selectedFacility) => (
      <ComponentPaneLayout>
        <ShopMembersPage shopDepartmentId={shopDepartmentId} selectedFacility={selectedFacility} />
      </ComponentPaneLayout>
    )}
  </ShopDepartmentRequired>
);

export const WorkCellsAndMachinesInterfaceNavContent = () => (
  <ShopDepartmentRequired>
    {(_, selectedFacility) => (
      <ShopSetupWrapper selectedFacility={selectedFacility}>
        <ComponentPaneLayout sizes={[1.4, 1]}>
          <WorkCellSetupPage />
          <WorkCellTaskAdder />
        </ComponentPaneLayout>
      </ShopSetupWrapper>
    )}
  </ShopDepartmentRequired>
);

export const WorkRequestsNavContent = () => (
  <FacilityOrProjectRequired projectRequired facilityRequired>
    {(selectedItem) => (
      <NoShopDptOverlay>
        <ComponentPaneLayout>
          <WorkRequestsPage selectedItem={selectedItem} />
        </ComponentPaneLayout>
      </NoShopDptOverlay>
    )}
  </FacilityOrProjectRequired>
);

export const WorkRequestNavContent = () => (
  <FacilityOrProjectRequired facilityRequired projectRequired>
    {(selectedItem) => (
      <WorkRequestSelectedWrapper selectedItem={selectedItem}>
        <ComponentPaneLayout sizes={[1.4, 1]}>
          <WorkRequestItemsTable />
          <WorkRequestOrderDetailsPane />
        </ComponentPaneLayout>
      </WorkRequestSelectedWrapper>
    )}
  </FacilityOrProjectRequired>
);

export const WorkCellsInterfaceNavContent = () => (
  <ShopDepartmentRequired>
    {(_, selectedFacility) => (
      <ShopDepartmentFallback>
        <ComponentPaneLayout componentPaneProps={{ pb: 0 }}>
          <WorkCellsPage selectedFacility={selectedFacility} />
        </ComponentPaneLayout>
      </ShopDepartmentFallback>
    )}
  </ShopDepartmentRequired>
);

export const AddEditCatalogItemNavContent = () => (
  <PartEditorProvider>
    {(part) => (
      <AssemblyEditorProvider part={part}>
        <ComponentPaneLayout sizes={[1.5, 1]}>
          <AssemblyEditorView />
          <AddEditItemPane />
        </ComponentPaneLayout>
      </AssemblyEditorProvider>
    )}
  </PartEditorProvider>
);

export const CatalogSetupInterfaceNavContent = () => (
  <CatalogSetupProvider>
    <ComponentPaneLayout>
      <CatalogSetupCategorySelector />
      <CatalogSetupDetailsPane />
    </ComponentPaneLayout>
  </CatalogSetupProvider>
);

export const WorkOrdersNavContent = () => (
  <GeneralWorkRequestProvider>
    <ShopDepartmentRequired>
      {(_, selectedFacility) => (
        <PopoverFilterProvider>
          <WorkOrderUpdateProvider>
            <UpdateWorkRequestStatusProvider>
              <WorkOrdersProvider>
                <WorkOrderWRProvider>
                  <CalendarProvider allowViewPicker defaultLayout={layoutOptions.YEAR}>
                    <ComponentPaneLayout componentPaneProps={{ pb: '8px' }}>
                      <WorkOrdersPage selectedFacility={selectedFacility} />
                    </ComponentPaneLayout>
                  </CalendarProvider>
                </WorkOrderWRProvider>
              </WorkOrdersProvider>
            </UpdateWorkRequestStatusProvider>
          </WorkOrderUpdateProvider>
        </PopoverFilterProvider>
      )}
    </ShopDepartmentRequired>
  </GeneralWorkRequestProvider>
);

export const WorkCellQueueNavContent = () => (
  <ShopDepartmentRequired>
    {(_, selectedFacility) => (
      <ComponentPaneLayout>
        <WorkCellQueuePage selectedFacility={selectedFacility} />
      </ComponentPaneLayout>
    )}
  </ShopDepartmentRequired>
);

export const TaskViewerNavContent = () => (
  <ComponentPaneLayout>
    <TaskViewer />
  </ComponentPaneLayout>
);

export const WorkOrderItemsNavContent = () => (
  <ShopDepartmentRequired>
    {(_, selectedFacility) => (
      <WorkOrderSelectedWrapper selectedItem={selectedFacility}>
        <ComponentPaneLayout sizes={[1.4, 1]}>
          <WorkOrderItemsTable />
          <WorkRequestOrderDetailsPane />
        </ComponentPaneLayout>
      </WorkOrderSelectedWrapper>
    )}
  </ShopDepartmentRequired>
);

export const DrawingRegisterInterfaceNavContent = () => (
  <FacilityAndProjectWrapper isFacilityRequired isProjectRequired>
    <ProjectProvider>
      <WorkOrderUpdateProvider>
        <UpdateWorkRequestStatusProvider>
          <WorkRequestsProvider>
            <WorkRequestItemsProvider>
              <DrawingRegisterProvider>
                <PopoverFilterProvider>
                  <DrawingRegister />
                </PopoverFilterProvider>
              </DrawingRegisterProvider>
            </WorkRequestItemsProvider>
          </WorkRequestsProvider>
        </UpdateWorkRequestStatusProvider>
      </WorkOrderUpdateProvider>
    </ProjectProvider>
  </FacilityAndProjectWrapper>
);

export const CustomAssemblyNavContent = () => {
  const { selectedItem } = useFacilitiesProjects();

  const Component = () => {
    if (selectedItem?.type === 'PROJECT' && isNotNil(selectedItem.id)) return <Navigate to={selectedItem.id} />;
    return <CustomAssembly />;
  };

  return (
    <FacilityAndProjectWrapper isFacilityRequired isProjectRequired>
      <ComponentPaneLayout>
        <Component />
      </ComponentPaneLayout>
    </FacilityAndProjectWrapper>
  );
};

export const CustomAssemblyByIdNavContent = () => {
  const Component = () => <CustomAssemblyTypesList />;

  return (
    <ProjectProvider>
      <CustomAssemblyTypesProvider>
        <CustomAssembliesProvider>
          <ComponentPaneLayout>
            <Component />
          </ComponentPaneLayout>
        </CustomAssembliesProvider>
      </CustomAssemblyTypesProvider>
    </ProjectProvider>
  );
};

export const BillOfMaterialsInterfaceNavContent = () => {
  const { selectedItem } = useFacilitiesProjects();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (selectedItem === null && params.id) navigate('../bill-of-materials');
    // eslint-disable-next-line
  }, [selectedItem]);

  return (
    <FacilityAndProjectWrapper isFacilityRequired isProjectRequired>
      <ItemsDataGridProProvider contextId="BillOfMaterials">
        <ComponentPaneLayout>
          <BillOfMaterials />
        </ComponentPaneLayout>
      </ItemsDataGridProProvider>
    </FacilityAndProjectWrapper>
  );
};
export const BillOfMaterialsByIdInterfaceNavContent = () => {
  const { selectedItem } = useFacilitiesProjects();
  const navigate = useNavigate();
  const params = useParams();
  const BOMRef = useRef<any>();
  const partDroppables = useRef<any>();

  const onDropPartDroppable = useCallback(({ draggableId }: { draggableId: string }) => {
    const partDroppable = partDroppables.current?.parts?.find(({ partId }: any) => partId === draggableId);
    BOMRef.current.handleDropItem(partDroppable);
  }, []);

  useEffect(() => {
    if (selectedItem === null && params.id) navigate('../bill-of-materials');
    // eslint-disable-next-line
  }, [selectedItem]);

  return (
    <FiltersProviderBase>
      <BOMbyIdProvider>
        <DragDropContext onDragEnd={onDropPartDroppable}>
          <AssignationModeProvider>
            <BOMItemProvider>
              <FacilityAndProjectWrapper isFacilityRequired isProjectRequired>
                <ComponentPaneLayout flyoutMenu={<BillOfMaterialsFlyoutMenu ref={partDroppables} />}>
                  <ItemsDataGridProProvider contextId="BillOfMaterialsById">
                    {/* @ts-expect-error ref isn't a prop here, I don't think this works */}
                    <BillOfMaterialsById ref={BOMRef} />
                  </ItemsDataGridProProvider>
                </ComponentPaneLayout>
              </FacilityAndProjectWrapper>
            </BOMItemProvider>
          </AssignationModeProvider>
        </DragDropContext>
      </BOMbyIdProvider>
    </FiltersProviderBase>
  );
};
