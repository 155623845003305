import { useCallback, useEffect } from 'react';

import { Tabs } from '@mantine/core';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { canEditWorkRequest, canEditWorkOrder } from 'modules/Shop/WorkOrders/WorkOrdersPage/common';

import { useWorkRequestOrderItems } from '../WorkRequestOrderItemsContext';
import { AddWriteInItem } from './AddItems/AddWriteInItem';
import { Catalog } from './AddItems/Catalog';
import { TasksView } from './AddTasks/TasksView';
import { WorkRequestOrderDetailList } from './WorkRequestOrderDetail/WorkRequestOrderDetailList';
import { WorkRequestOrderPartAdder } from './WorkRequestOrderPartAdder';

export type DetailTab = 'detail' | 'catalog' | 'tasks';

export const WorkRequestOrderDetailsPane = () => {
  const props = useWorkRequestOrderItems();
  const { setSelectedTab, selectedTab } = props;
  const canEditItems =
    'workOrder' in props
      ? canEditWorkOrder(props.workOrder.workOrderStatusTypeName, 'editItems')
      : canEditWorkRequest(props.workRequest.workRequestStatusName, 'editItems');
  const canEditTasks =
    props.selectedItem.type === 'FACILITY' &&
    ('workOrder' in props
      ? canEditWorkOrder(props.workOrder.workOrderStatusTypeName, 'editTasks')
      : canEditWorkRequest(props.workRequest.workRequestStatusName, 'editTasks'));
  useEffect(() => {
    if (!canEditItems && !canEditTasks && selectedTab !== 'detail') {
      setSelectedTab('detail');
    }
  }, [canEditItems, canEditTasks, selectedTab, setSelectedTab]);
  const refresh = useCallback(() => props.serverSideGridProps.refreshGrid(), [props.serverSideGridProps]);
  return (
    <Tabs
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
      value={selectedTab}
      onTabChange={(t: DetailTab) => setSelectedTab(t ?? selectedTab)}
      variant="outline"
      orientation="horizontal"
      keepMounted={canEditItems || canEditTasks}
    >
      <Tabs.List mb="xs">
        <Tabs.Tab
          value="detail"
          icon={
            <EvolveIcon icon={'workOrder' in props ? 'WorkOrder' : 'WorkRequest'} dark={selectedTab === 'detail'} />
          }
        >
          Properties
        </Tabs.Tab>
        <Tabs.Tab
          value="catalog"
          disabled={!canEditItems}
          icon={<EvolveIcon icon="Assembly" dark={selectedTab === 'catalog'} />}
          style={{ cursor: !canEditItems ? 'initial' : undefined }}
        >
          Add Items
        </Tabs.Tab>
        <Tabs.Tab
          value="tasks"
          disabled={!canEditTasks}
          icon={<EvolveIcon icon="WorkCellTask" dark={selectedTab === 'tasks'} />}
          style={{ cursor: !canEditTasks ? 'initial' : undefined }}
        >
          Add Tasks
        </Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="detail" style={{ overflowY: 'scroll', scrollbarWidth: 'none' }}>
        <WorkRequestOrderDetailList {...props} isDrawer={false} />
      </Tabs.Panel>
      <Tabs.Panel value="catalog" display="flex" style={{ flexDirection: 'column', flex: '1 1 auto' }}>
        <Catalog
          rightSideComponent={<AddWriteInItem {...props} refresh={refresh} />}
          refresh={refresh}
          companyId={props.selectedItem.companyId}
        >
          {(listingProps) => <WorkRequestOrderPartAdder {...listingProps} {...props} />}
        </Catalog>
      </Tabs.Panel>
      <Tabs.Panel value="tasks" display="flex" style={{ flexDirection: 'column', flex: '1 1 auto' }}>
        <TasksView
          {...props}
          refresh={(billOfProcessId) => {
            props.serverSideGridProps.refreshDetailGrid(billOfProcessId, 'Bill of Process');
            props.serverSideGridProps.expandDetailGrid(billOfProcessId, 'Bill of Process');
          }}
        />
      </Tabs.Panel>
    </Tabs>
  );
};
