import { createContext, ReactNode, useMemo, useState } from 'react';

import useGeneralContext from 'helpers/useGeneralContext';
import { useLocalStorage } from 'hooks/useLocalStorage';
import type {
  PartCatalogId,
  PartCategoryId,
} from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/AddItems/types';

type CatalogSetupContextType = {
  selectedCatalogId: PartCatalogId | null;
  setSelectedCatalogId: (partCatalogId: CatalogSetupContextType['selectedCatalogId']) => void;
  selectedCategoryId: PartCategoryId | null;
  setSelectedCategoryId: (partCategoryId: CatalogSetupContextType['selectedCategoryId']) => void;
  selectedTab: 'category-setup' | 'manage-items';
  setSelectedTab: (tab: CatalogSetupContextType['selectedTab']) => void;
};

const CatalogSetupContext = createContext<CatalogSetupContextType | undefined>(undefined);

export const CatalogSetupProvider = ({ children }: { children: ReactNode }) => {
  const [selectedCatalogId, setSelectedCatalogId] = useLocalStorage<'SELECTED_CATALOG_ID'>('SELECTED_CATALOG_ID', null);
  const [selectedCategoryId, setSelectedCategoryId] = useState<CatalogSetupContextType['selectedCategoryId']>(null);
  const [selectedTab, setSelectedTab] = useState<CatalogSetupContextType['selectedTab']>('manage-items');
  return (
    <CatalogSetupContext.Provider
      value={useMemo(
        () => ({
          selectedTab,
          setSelectedTab,
          selectedCatalogId,
          setSelectedCatalogId,
          selectedCategoryId,
          setSelectedCategoryId,
        }),
        [selectedCatalogId, selectedCategoryId, selectedTab, setSelectedCatalogId],
      )}
    >
      {children}
    </CatalogSetupContext.Provider>
  );
};

export const useCatalogSetup = () => useGeneralContext(CatalogSetupContext, 'CatalogSetup');
