import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { addHours, isBefore } from 'date-fns';

import { isNotNil } from 'helpers/isNotNil';
import { strongKeys } from 'helpers/strongEntries';
import useGeneralContext from 'helpers/useGeneralContext';
import { useWrappedPaginatedGet, useWrappedPost } from 'hooks-api/useWrappedApiCall';
import type { UserId } from 'types/types-api';

import type { Document, DocumentId } from './types';

type GetPresignedUrlBody = {
  expirationHours: number;
  objectKey: string;
  requestedBy: UserId;
  verb: 'GET' | 'PUT';
};

export type GetPresignedUrlResponse = {
  contentType: string;
  expirationHours: number;
  objectKey: string;
  preSignedURL: string;
};

type DocumentsCacheContextType = {
  getPresignedUrl: (body: GetPresignedUrlBody) => Promise<GetPresignedUrlResponse>;
  getPresignedUrlForDocument: (
    documentId: DocumentId,
    body: Omit<GetPresignedUrlBody, 'objectKey'>,
  ) => Promise<GetPresignedUrlResponse | null>;
  presigningInProgress: boolean;
  attachmentToDocumentMap: Record<DocumentId, Document | undefined>;
  addDocumentToCache: (document: Document) => void;
  loadingDocuments: boolean;
  requestDocumentDetails: (documentIds: DocumentId[]) => void;
};

const DocumentsCacheContext = React.createContext<DocumentsCacheContextType | undefined>(undefined);

const FILE_EXTENSIONS = /\.(jpg|jpeg|png|gif|heic|pdf)$/;

export const DocumentsCacheProvider = ({ children }: { children: ReactNode }) => {
  const { fetchPage: fetchDocuments, loading: loadingDocuments } = useWrappedPaginatedGet<Document>('docmgt/document', {
    lazy: true,
  });
  const [documentIdsNeedingDocuments, setDocumentIdsNeedingDocuments] = useState<DocumentId[]>([]);
  const [attachmentToDocumentMap, setAttachmentToDocumentMap] = useState<
    DocumentsCacheContextType['attachmentToDocumentMap']
  >({});
  const attachmentToDocumentMapRef =
    useRef<DocumentsCacheContextType['attachmentToDocumentMap']>(attachmentToDocumentMap);
  useEffect(() => {
    attachmentToDocumentMapRef.current = attachmentToDocumentMap;
  }, [attachmentToDocumentMap]);

  const presignedUrlCache = useRef<
    Record<
      /** `objectKey` */
      string,
      {
        response: GetPresignedUrlResponse;
        expiration: Date;
      }
    >
  >({});

  const addDocumentToCache = useCallback((document: Document) => {
    setAttachmentToDocumentMap((a) => ({
      ...a,
      [document.documentId]: document,
    }));
  }, []);

  const { apiCall: getPresignedUrlApiCall, loading: presigningInProgress } = useWrappedPost<
    GetPresignedUrlResponse,
    GetPresignedUrlBody
  >('docmgt/document/preSignedUrl');

  const getPresignedUrl = useCallback(
    async (req: GetPresignedUrlBody) => {
      const rightNow = new Date();
      const { objectKey } = req;
      if (objectKey in presignedUrlCache.current) {
        const { expiration, response } = presignedUrlCache.current[objectKey];
        if (isBefore(rightNow, expiration)) {
          return response;
        }
      }
      const response = await getPresignedUrlApiCall(req);
      presignedUrlCache.current[response.objectKey] = {
        response,
        expiration: addHours(rightNow, response.expirationHours),
      };
      return response;
    },
    [getPresignedUrlApiCall],
  );

  const requestDocumentDetails = useCallback<DocumentsCacheContextType['requestDocumentDetails']>((newIds) => {
    setDocumentIdsNeedingDocuments((ids) => [...ids, ...newIds]);
  }, []);

  useEffect(() => {
    if (documentIdsNeedingDocuments.length > 0) {
      const alreadyFetched = strongKeys(attachmentToDocumentMapRef.current);
      const documentIds = documentIdsNeedingDocuments.filter((id) => !alreadyFetched.includes(id));
      if (documentIds.length > 0) {
        fetchDocuments(
          {
            skip: 0,
            take: documentIds.length,
          },
          { params: { documentIds: documentIds.join(',') } },
        ).then(({ data: documents }) => {
          setAttachmentToDocumentMap((map) => {
            const output = { ...map };
            documents.forEach((document) => {
              output[document.documentId] = document;
            });
            return output;
          });
        });
      }
      setDocumentIdsNeedingDocuments([]);
    }
  }, [documentIdsNeedingDocuments, fetchDocuments]);

  const getPresignedUrlForDocument = useCallback<DocumentsCacheContextType['getPresignedUrlForDocument']>(
    async (documentId, body) => {
      const document =
        attachmentToDocumentMapRef.current[documentId] ??
        (await fetchDocuments({ skip: 0, take: 1 }, { params: { documentIds: documentId } }).then(
          (res) => res.data[0],
        ));
      if (isNotNil(document)) {
        addDocumentToCache(document);
        const { storagePath, documentName } = document;
        const objectKey = storagePath.match(FILE_EXTENSIONS) ? storagePath : `${storagePath}/${documentName}`;
        return getPresignedUrl({
          ...body,
          objectKey,
        });
      }
      return null;
    },
    [addDocumentToCache, fetchDocuments, getPresignedUrl],
  );

  const value = useMemo<DocumentsCacheContextType>(
    () => ({
      getPresignedUrl,
      getPresignedUrlForDocument,
      presigningInProgress,
      attachmentToDocumentMap,
      addDocumentToCache,
      requestDocumentDetails,
      loadingDocuments,
    }),
    [
      getPresignedUrl,
      getPresignedUrlForDocument,
      presigningInProgress,
      attachmentToDocumentMap,
      addDocumentToCache,
      requestDocumentDetails,
      loadingDocuments,
    ],
  );
  return <DocumentsCacheContext.Provider value={value}>{children}</DocumentsCacheContext.Provider>;
};

export const useDocumentsCache = () => useGeneralContext(DocumentsCacheContext, 'DocumentsCache');
