import { useEffect } from 'react';

import { ConfirmationModal } from 'components/Mantine/ConfirmationModal';

import { useCallbackPrompt } from './useCallbackPrompt';

type Props = {
  isDirty: boolean;
  title?: string;
  subtitle?: string;
};

export const ConfirmNavigationModal = ({
  title = 'Confirm navigation',
  subtitle = 'Changes you made will not be saved.',
  isDirty,
}: Props) => {
  const { showPrompt, confirmNavigation, cancelNavigation } = useCallbackPrompt(isDirty);
  useEffect(() => {
    if (showPrompt && !isDirty) {
      confirmNavigation();
    }
  }, [confirmNavigation, isDirty, showPrompt]);
  return (
    <ConfirmationModal
      title={title}
      onConfirm={confirmNavigation}
      onClose={cancelNavigation}
      opened={showPrompt}
      description={subtitle}
      buttonColor="red"
    />
  );
};
