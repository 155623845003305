import { useEffect, useState } from 'react';

import { Card, Flex, Input, Modal, Select, Textarea, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';

import { ModalButtons } from 'components/Mantine/ModalButtons';
import { LOCAL_STORAGE_CONSTANTS } from 'constants/globalConstants';
import { getAllDataFromFetcher } from 'helpers/getAllDataFromFetcher';
import { isNil, isNotNil } from 'helpers/isNotNil';
import { useWrappedPaginatedGet, useWrappedPost } from 'hooks-api/useWrappedApiCall';
import { useSelectedProjectFacility } from 'hooks/projectsAndFacilities/useSelectedProjectFacility';
import { getLocalStorage } from 'hooks/useLocalStorage';
import { ASSEMBLY_ITEM_TYPE_ID } from 'modules/Field/WorkRequests/WorkRequest/constants';
import type {
  Task,
  WorkRequestItem,
  WorkRequestItemTypeId,
} from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/types';
import {
  ManufacturerId,
  useManufacturers,
} from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/useManufacturers';
import {
  useUnitOfMeasure,
  type UnitOfMeasureId,
} from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/useUnitOfMeasure';
import type { Assembly } from 'modules/Materials/CatalogSetup/CatalogSetupPage/PartEditor/types';

import { createAssemblyFromWriteInItem } from './assemblyEditorHelper';
import { CatalogCategoryNav } from './SecondaryPane/AddItems/CatalogCategoryNav';
import { CatalogSelect } from './SecondaryPane/AddItems/CatalogSelect';
import type { Part, PartCatalogId, PartCategoryId } from './SecondaryPane/AddItems/types';
import type {
  CreateOrUpdateWorkOrderItemBody,
  CreateOrUpdateWorkRequestItemBody,
  NewAssemblyBody,
  WorkOrderItem,
  WorkOrderItemTypeId,
} from './types';

type Props = {
  opened: boolean;
  onClose: () => void;
} & (
  | {
      workRequestItem: WorkRequestItem;
      updateWorkRequestItem: (v: CreateOrUpdateWorkRequestItemBody) => Promise<void>;
      workOrderItem?: never;
    }
  | {
      workOrderItem: WorkOrderItem;
      updateWorkOrderItem: (v: CreateOrUpdateWorkOrderItemBody) => Promise<void>;
      workRequestItem?: never;
    }
);

const AddToCatalogForm = ({ opened, onClose, workRequestItem, workOrderItem, ...props }: Props) => {
  const { selectedItem } = useSelectedProjectFacility();
  const defaultCatalogId = getLocalStorage<PartCatalogId>(LOCAL_STORAGE_CONSTANTS.SELECTED_CATALOG_ID);
  const [selectedCatalogId, setSelectedCatalogId] = useState<PartCatalogId | null>(defaultCatalogId ?? null);
  const [selectedCategoryId, setSelectedCategoryId] = useState<PartCategoryId | null>(null);
  useEffect(() => setSelectedCategoryId(null), [selectedCatalogId]);
  const { manufacturers, loading: loadingManufacturers } = useManufacturers();
  const { uoms, loading: loadingUoms } = useUnitOfMeasure();
  const { fetchPage: getTasks } = useWrappedPaginatedGet<Task>('shop/task', { lazy: true });
  const [loadingTasks, setLoadingTasks] = useState(false);
  const [allTasks, setAllTasks] = useState<Task[]>([]);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (opened) {
      setLoadingTasks(true);
      getAllDataFromFetcher(getTasks, {
        params: { workRequestItemId: (workOrderItem ?? workRequestItem).workRequestItemId },
      })
        .then(setAllTasks)
        .finally(() => setLoadingTasks(false));
    }
  }, [getTasks, workOrderItem, workRequestItem, opened]);

  const form = useForm<{
    partName: string;
    description: string | undefined;
    manufacturerId: ManufacturerId;
    unitOfMeasureId: UnitOfMeasureId;
  }>({
    initialValues: {
      partName: workOrderItem ? workOrderItem.workOrderItemName : workRequestItem.workRequestItemName,
      description: '',
      manufacturerId:
        manufacturers?.find((m) => m.manufacturerName === 'Generic')?.manufacturerId ?? ('' as ManufacturerId),
      unitOfMeasureId: workOrderItem ? workOrderItem.unitOfMeasureId : workRequestItem.unitOfMeasureId,
    },
    validate: {
      partName: (n) => n.length === 0,
      manufacturerId: (id) => id.length === 0,
      unitOfMeasureId: (id) => id.length === 0,
    },
  });

  const { apiCall: addPart } = useWrappedPost<
    Part,
    {
      partName: string;
      description?: string;
      unitOfMeasureId: UnitOfMeasureId;
      manufacturerId: ManufacturerId;
      partCategoryId: PartCategoryId;
    }
  >('moab/part');
  const { apiCall: addAssembly } = useWrappedPost<Assembly, NewAssemblyBody>('moab/assembly');

  if (isNil(selectedItem)) return null;

  const onSubmit = async (values: typeof form.values) => {
    if (isNil(selectedCategoryId)) return;
    setSaving(true);
    try {
      const part = await addPart({ ...values, partCategoryId: selectedCategoryId });
      await addAssembly(createAssemblyFromWriteInItem(part, allTasks));
      notifications.show({
        title: 'Successfully added',
        message: `Added ${part.partName} to catalog`,
        color: 'green',
      });
      await ('updateWorkOrderItem' in props
        ? props.updateWorkOrderItem({
            workOrderItemName: values.partName,
            unitOfMeasureId: values.unitOfMeasureId,
            workOrderItemTypeId: ASSEMBLY_ITEM_TYPE_ID as WorkOrderItemTypeId,
            partId: part.partId,
          })
        : props.updateWorkRequestItem({
            workRequestItemName: values.partName,
            unitOfMeasureId: values.unitOfMeasureId,
            workRequestItemTypeId: ASSEMBLY_ITEM_TYPE_ID as WorkRequestItemTypeId,
            partId: part.partId,
          }));
      onClose();
    } finally {
      setSaving(false);
    }
  };

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Flex gap="sm" style={{ width: '100%' }}>
        <Flex
          direction="column"
          style={{
            width: 500,
            height: 355,
          }}
        >
          <CatalogSelect
            showLabel
            selectProps={{ required: true }}
            companyId={selectedItem.companyId}
            onCatalogSelected={setSelectedCatalogId}
            selectedCatalogId={selectedCatalogId}
            disabled={saving}
          />
          <Input.Label mt="sm" required>
            Category
          </Input.Label>
          <Card
            withBorder
            p="xs"
            py={0}
            bg={saving ? 'gray.2' : undefined}
            style={{
              height: '100%',
              overflowY: 'scroll',
              scrollbarWidth: 'none',
            }}
          >
            {isNotNil(selectedCatalogId) && (
              <CatalogCategoryNav
                partCatalogId={selectedCatalogId}
                setSelectedCategoryId={setSelectedCategoryId}
                selectedCategoryId={selectedCategoryId}
                companyId={selectedItem.companyId}
                disabled={saving}
              />
            )}
          </Card>
        </Flex>
        <Card bg="gray.1" style={{ width: '100%' }}>
          <Flex direction="column" gap="md">
            <TextInput label="Generic Part Name" disabled={saving} withAsterisk {...form.getInputProps('partName')} />
            <Textarea label="Description" disabled={saving} {...form.getInputProps('description')} />
            <Select
              label="Manufacturer"
              withAsterisk
              nothingFound={loadingManufacturers ? 'Loading...' : 'No manufacturers found.'}
              disabled={saving}
              data={
                manufacturers?.map((m) => ({
                  label: m.manufacturerName,
                  value: m.manufacturerId,
                })) ?? []
              }
              {...form.getInputProps('manufacturerId')}
            />
            <Select
              label="Unit of Measure"
              withAsterisk
              nothingFound={loadingUoms ? 'Loading...' : 'No existing UOMs found.'}
              disabled={saving}
              data={
                uoms?.map((uom) => ({
                  label: `${uom.unitOfMeasureName} (${uom.unitOfMeasureCode})`,
                  value: uom.unitOfMeasureId,
                })) ?? []
              }
              {...form.getInputProps('unitOfMeasureId')}
            />
          </Flex>
        </Card>
      </Flex>
      <ModalButtons
        dividerMargin="md"
        onClose={onClose}
        confirmationText="Submit"
        loading={saving}
        disabled={!form.isValid() || !selectedCategoryId || loadingTasks}
        type="submit"
      />
    </form>
  );
};

export const AddToCatalogModal = (props: Props) => {
  const { opened, onClose } = props;
  return (
    <Modal
      title="Add item to catalog category"
      size="lg"
      opened={opened}
      onClose={onClose}
      centered
      closeOnClickOutside={false}
      closeOnEscape={false}
    >
      <AddToCatalogForm {...props} />
    </Modal>
  );
};
